import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isLoggedIn } from "../utils/authHelpers";

const PublicRoute = () => {
  const location = useLocation();

  if (location.pathname === "/") {
    return isLoggedIn() ? <Navigate to="/admin/dashboard" replace /> : <Outlet />;
  }

  return <Outlet />;
};

export default withTranslation()(PublicRoute);
