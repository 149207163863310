/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import fetchUserStatus, { loginUser } from "../thunks/userThunk";
import { IUserAuthResponse } from "../../api/auth";

interface IUserSlice {
  user: IUserAuthResponse | null;
}

const initialState: IUserSlice = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: { payload: IUserAuthResponse }) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserStatus.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
