import Purchases from "../../views/Purchases/Purchases";
import Dashboard from "../../views/Dashboard";
import UserPasswordEdit from "../../views/Settings/User/UserPasswordEdit";
import IRouteModel from "../../models/IRouteModel";
import OfflineOrder from "../../views/OfflineOrder/OfflineOrder";
import SignupDocuments from "../../views/Merchant/SignupDocuments";

const privateRoutes: IRouteModel[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: Purchases,
    layout: "/admin",
    permission: "get.business.purchases.accounts.*",
  },
  {
    path: "/offlineorder",
    name: "Offline Order",
    component: OfflineOrder,
    layout: "/admin",
    permission: "post.business.purchases.accounts.*.offline",
  },
  {
    path: "/settings",
    name: "Settings",
    component: UserPasswordEdit,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/process/:processSessionHash/:timestamp",
    name: "SignupDocuments",
    component: SignupDocuments,
    layout: "/admin",
    hidden: true,
  },
];

export default privateRoutes;
