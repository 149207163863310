import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Admin from "../layouts/Admin";
import PublicLayout from "../layouts/PublicLayout";
import Login from "../views/Login";
import Signup from "../views/Signup";
import LoginWithEmail from "../views/LoginWithEmail";
import Page404 from "../views/Page404";
import privateRoutes from "./private/privateRoutes";
import publicRoutes from "./public/publicRoutes";
import publicFooterRoutes from "./public/publicFooterRoutes";
import privateFooterRoutes from "./private/privateFooterRoutes";
import Gandalf from "../components/Gandalf";
import UserAuthLayout from "../layouts/UserAuthLayout";
import i18n from "../i18n";

const RouteElement = ({ permission, component }: { permission: string; component: React.ElementType }) => {
  const CustomComponent = component;
  return (
    <Gandalf permission={permission}>
      <CustomComponent />
    </Gandalf>
  );
};

const RoutedContent: React.FC = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={i18n.language}>
      <Route element={<PrivateRoute />}>
        <Route element={<Admin />}>
          {privateRoutes.map(({ component, path, layout, permission }) => {
            return (
              <Route
                key={layout + path}
                element={<RouteElement permission={permission || ""} component={component} />}
                path={layout + path}
              />
            );
          })}
          {privateFooterRoutes.map(({ component, path, layout, permission }) => {
            return (
              <Route
                key={layout + path}
                element={<RouteElement permission={permission || ""} component={component} />}
                path={layout + path}
              />
            );
          })}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route element={<UserAuthLayout />}>
          <Route element={<Signup />} path="merchant/signup/:token" />
          <Route element={<Signup />} path="merchant/signup" />
          <Route element={<Signup key="process" />} path="process/:processSessionHash/:timestamp" />
          <Route element={<Login />} path="login/:token" />
          <Route element={<LoginWithEmail />} path="login-with-email" />
          <Route element={<Login />} path="/" />
        </Route>
        <Route element={<PublicLayout />}>
          {publicRoutes.map(({ component, path, layout }) => {
            const CustomComponent = component;

            return <Route key={layout + path} element={<CustomComponent />} path={path} />;
          })}
          {publicFooterRoutes.map(({ component, path, layout }) => {
            const CustomComponent = component;

            return <Route key={layout + path} element={<CustomComponent />} path={path} />;
          })}
        </Route>
      </Route>
    </Routes>
  );
};

export default RoutedContent;
