import getCookie from "./getCookie";
// eslint-disable-next-line import/no-cycle
import { revokeUserAuthTokenRequest } from "../api/auth";
import { deleteActiveBusinessAccountStorage } from "./activeBusinessAccountCookie";

export const authTokenName = "auth";

export function deleteAuthToken(): void {
  document.cookie = `${authTokenName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function setAuthToken(): void {
  document.cookie = `${authTokenName}=true; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/; SameSite=strict; path=/`;
}

export function isLoggedIn(): boolean {
  return getCookie(authTokenName) === "true";
}

export function logout(): void {
  if (isLoggedIn()) {
    deleteAuthToken();
    deleteActiveBusinessAccountStorage();

    void revokeUserAuthTokenRequest().then(() => {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    });
  } else {
    window.location.href = "/";
  }
}
