import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  businessAccountDetailsUpdate,
  businessAccountInfo,
  businessAccounts,
  IBusinessAccountUpdate,
} from "../../api/businessAccount";

export const fetchBusinessAccounts = createAsyncThunk("business/fetchBusinessAccounts", async () => {
  const response = await businessAccounts();
  return response;
});

export const fetchActiveBusinessAccountInfo = createAsyncThunk(
  "business/fetchBusinessAccountInfo",
  async (_, { getState }) => {
    const { activeBusinessAccount } = getState().business;
    if (!activeBusinessAccount) return null;

    const response = await businessAccountInfo(activeBusinessAccount.hash);
    return response;
  }
);

export const fetchActiveBusinessAccountDetailsUpdate = createAsyncThunk(
  "business/fetchBusinessAccountUpdate",
  async (payload: IBusinessAccountUpdate, { getState }) => {
    const { activeBusinessAccount } = getState().business;
    if (!activeBusinessAccount) return null;

    const response = await businessAccountDetailsUpdate(activeBusinessAccount.hash, {
      ...activeBusinessAccount,
      ...payload,
    });
    return response;
  }
);
