import API from "./api";

const businessAccountsURL = "/business/settings/accounts";
const merchantIndustriesURL = "/business/settings/merchant/industries";

interface IBusinessAccountAddress {
  street: string;
  postal_code: string;
  locality: string;
  subregion: string;
  country_iso: string;
}

export interface IBusinessAccount {
  hash: string;
  name: string;
  time_zone: string;
  business_name: string;
  business_url: string;
  business_address: IBusinessAccountAddress;
  support_email: string;
  support_phone: string;
  support_phone_country_code: string;
  merchant_industry: string;
  signup_turnover: string;
  signup_activity_since: string;
  credentials: {
    username: string;
    password: string;
  };
}

export interface IBusinessAccountTeam {
  Email: string;
  Hash: string;
  Name: string;
  Role: string;
  RoleHash: string;
}

export interface IMerchantIndustry {
  hash: string;
  code: string;
  properties: {
    categories: string[];
  };
}

export interface IBusinessAccountUpdate {
  merchant_industry: string;
  signup_turnover: string;
  signup_activity_since: string;
}

export const businessAccounts = () => {
  return API.get(businessAccountsURL)
    .then(({ data }: { data: { data: IBusinessAccount[] | null } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const businessAccountInfo = (accountID: string) => {
  return API.get(`${businessAccountsURL}/${accountID}`)
    .then(({ data }: { data: { data: IBusinessAccount | null } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const businessAccountDetailsUpdate = (accountID: string, payload: IBusinessAccountUpdate) => {
  return API.put(`${businessAccountsURL}/${accountID}/business/details`, payload)
    .then(({ data }: { data: { data: IBusinessAccount | null } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const businessAccountTeam = (accountID: string) => {
  return API.get(`${businessAccountsURL}/${accountID}/team`)
    .then(({ data }: { data: { data: IBusinessAccountTeam[] | null } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const getMerchantIndustries = () => {
  return API.get(merchantIndustriesURL)
    .then(({ data }: { data: { data: IMerchantIndustry[] } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};
