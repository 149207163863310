import API from "./api";
import { IUserAuthResponse } from "./auth";

const authUserInfoURL = "/business/auth/user";
const settingsProfileURL = "/business/settings/profile";
const settingsProfilePasswordURL = "/business/settings/profile/password";

export const authUserInfo = () => {
  return API.get(authUserInfoURL)
    .then(({ data }: { data: IUserAuthResponse | null }) => {
      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const settingsProfileEdit = async ({
  firstName,
  lastName,
  phone,
}: {
  firstName: string;
  lastName: string;
  phone: string;
}) => {
  return API.put(settingsProfileURL, { first_name: firstName, last_name: lastName, phone, phone_country_code: "+34" })
    .then(({ data }: { data: { error: string } }) => {
      if (data.error) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const settingsProfilePasswordEdit = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  return API.put(settingsProfilePasswordURL, { current_password: currentPassword, new_password: newPassword })
    .then(({ data }: { data: { error: string } }) => {
      if (data.error) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const profilePasswordSetup = async ({ password }: { password: string }) => {
  return API.post(settingsProfilePasswordURL, { password })
    .then(({ data }: { data: { error: string } }) => {
      if (data.error) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const profilePasswordEdit = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  return API.put(settingsProfilePasswordURL, { current_password: currentPassword, new_password: newPassword })
    .then(({ data }: { data: { error: string } }) => {
      if (data.error) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    })
    .catch((error: { response: { data: string } }) => {
      return error.response.data;
    });
};
