import React, { useEffect, useState } from "react";
import i18n from "i18next";
import processPostByChannelAndName from "../../api/process";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/storeHooks";
import { RootState } from "../../store/store";
import useStyles from "../../hooks/useStyles";
import useScript from "../../hooks/useScript";
import Loader from "../../components/Loader";

const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const SignupDocuments = () => {
  const [processLoaded, setProcessLoaded] = useState(false);
  const navigate = useNavigate();
  const { processSessionHash } = useParams();
  const activeBusinessAccountCredentials = useAppSelector(selectActiveBusinessAccount)?.credentials || {
    username: process.env.REACT_APP_CALC_FLOW_CHANNEL || "",
  };

  useEffect(() => {
    if (processSessionHash) {
      setProcessLoaded(true);
      return;
    }
    processPostByChannelAndName("MERCHANT_STORE_DOCUMENTS", activeBusinessAccountCredentials.username)
      .then((data) => {
        if (data?.attribution?.process_session_hash) {
          setProcessLoaded(true);

          navigate(
            `/admin/process/${data.attribution.process_session_hash}/${Date.now().toString()}?xc=${
              activeBusinessAccountCredentials.username
            }`
          );
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useStyles(process.env.REACT_APP_CALC_STYLE_PATH || "");
  useScript(process.env.REACT_APP_CALC_JAVASCRIPT_PATH || "");

  return (
    <div style={{ position: "initial" }}>
      {processLoaded ? (
        <div
          id="app"
          data-fiizy-app
          data-full-screen={"false"}
          data-api-url={process.env.REACT_APP_API_URL?.replace("/api/v1", "")}
          data-channel-hash={activeBusinessAccountCredentials.username}
          data-language={i18n.language.split("-")[0]}
          data-locale={i18n.language}
          data-client-bundle-identifier={process.env.REACT_APP_CALC_FLOW_BUNDLE}
        ></div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default SignupDocuments;
