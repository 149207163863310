import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isLoggedIn, logout } from "../utils/authHelpers";

const PrivateRoute = () => {
  if (!isLoggedIn()) {
    logout();
    return <Navigate to={"/"} replace />;
  }

  return <Outlet />;
};

export default withTranslation()(PrivateRoute);
