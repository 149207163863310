import API from "./api";

const notificationsUrl = "/business/notifications/queue";

export interface Notification {
  hash: string;
  title: string;
  body: string;
  parameters: {
    needs_action: boolean;
    priority: "high" | "medium" | "low";
    target_path: string;
    user_level: boolean;
  };
}

const notifications = (businessAccountHash: string) => {
  return API.get(notificationsUrl, { params: { account_hash: businessAccountHash } })
    .then(({ data }: { data: { data: Notification[] } }) => {
      if (data.data) {
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export default notifications;
