import IRouteModel from "../../models/IRouteModel";
import PrivacyPolicy from "../../views/Legal/PrivacyPolicy";
import TermsAndConditions from "../../views/Legal/TermsAndConditions";
import CookiePolicy from "../../views/Legal/CookiePolicy";
import PrestashopVideoGuide from "../../views/PluginGuides/PrestashopVideoGuide";

const publicFooterRoutes: IRouteModel[] = [
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    layout: "",
    hidden: true,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms And Conditions",
    component: TermsAndConditions,
    layout: "",
    hidden: true,
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: CookiePolicy,
    layout: "",
    hidden: true,
  },
  {
    path: "/prestashop/guide",
    name: "Prestashop Video Guide",
    component: PrestashopVideoGuide,
    layout: "",
    hidden: true,
  },
];

export default publicFooterRoutes;
