import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormTextInput from "../../../components/FormFields/FormTextInput";
import { Control, FieldValues } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import Typography from "@mui/material/Typography";

interface IOfflineOrderProducts {
  errors: FieldErrors<FieldValues>;
  control: Control<{
    lineItems: { name: string; quantity: number; price: number }[];
    customer: { phoneNumber: string };
    client: { bundle_identifier: string };
  }>;
}

const OfflineOrderDetails = ({ errors, control }: IOfflineOrderProducts) => {
  const { t } = useTranslation("translation", { keyPrefix: "offlineOrder" });

  return (
    <Box sx={{ mt: 6, maxWidth: 335 }}>
      <Typography component="h1" variant="h4">
        {t("Order details")}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <FormTextInput
          key="phoneNumber"
          required="This is required."
          errors={errors}
          name="customer.phoneNumber"
          label={t("Customer Phone Number")}
          type="tel"
          control={control}
          inputMode="tel"
          pattern={/^[679]\d{8}$/}
          fieldArrayKey="customer"
          fieldName="phoneNumber"
        />
      </Box>
    </Box>
  );
};

export default OfflineOrderDetails;
