import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import mediakit from "../assets/img/mediakit.png";

const MediaKit = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h1" variant="h4">
        {t("routes.Media kit")}
      </Typography>
      <Box sx={{ mt: 4, maxWidth: 1040 }}>
        <Box>
          <img src={mediakit} alt="media kit preview" height="100%" width="100%" />
        </Box>
        <Typography component="p" sx={{ mt: 4 }}>
          {t(
            "Feel free to use our provided resources for promoting our BNPL service in your communication channels but please treat our brand well. Media kit will be updated frequently."
          )}
        </Typography>
        <Box style={{ textAlign: "center" }}>
          <Button
            href="https://cdn.fiizy.com/Fiizy+Media+kit+_+BNPL.zip"
            variant="contained"
            size="large"
            sx={{ mt: 5 }}
          >
            {t("Download media kit")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MediaKit;
