/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { IPurchase } from "../../api/purchases";
import fetchPurchases, { fetchItems, fetchPurchasesFees, fetchPurchasesTransactions } from "../thunks/purchasesThunk";

interface IPurchasesSlice {
  purchases: IPurchase[];
  loading: boolean;
  fetchPurchasesCurrentRequestId: string;
}

const initialState: IPurchasesSlice = {
  purchases: [],
  loading: false,
  fetchPurchasesCurrentRequestId: "",
};

export const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchases.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.fetchPurchasesCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchPurchases.fulfilled, (state, action) => {
        if (state.loading && state.fetchPurchasesCurrentRequestId === action.meta.requestId) {
          state.loading = false;
          state.purchases = action.payload;
          state.fetchPurchasesCurrentRequestId = "";
        }
      });
    builder.addCase(fetchPurchasesTransactions.fulfilled, (state, action) => {
      action.payload.forEach((transaction) => {
        const purchaseIndex = state.purchases.findIndex((purchase) => purchase.order_hash === transaction.order_hash);
        if (purchaseIndex) {
          state.purchases[purchaseIndex].transaction = transaction;
        }
      });
    });
    builder.addCase(fetchPurchasesFees.fulfilled, (state, action) => {
      const purchaseHashes = [...Array.from(new Set(action.payload.map((item) => item.order_hash)))];
      purchaseHashes.forEach((purchaseHash) => {
        const purchaseIndex = state.purchases.findIndex((purchase) => purchase.order_hash === purchaseHash);

        // TODO: in view please use useMemo to avoid re-renders if api sends back same fees
        state.purchases[purchaseIndex].fees = action.payload.filter((fee) => fee.order_hash === purchaseHash);
      });
    });
    builder.addCase(fetchItems.fulfilled, (state, action) => {
      const purchaseHashes = [...Array.from(new Set(action.payload.map((item) => item.order_hash)))];
      purchaseHashes.forEach((purchaseHash) => {
        const purchaseIndex = state.purchases.findIndex((purchase) => purchase.order_hash === purchaseHash);

        // TODO: in view please use useMemo to avoid re-renders if api sends back same
        state.purchases[purchaseIndex].items = action.payload.filter((item) => item.order_hash === purchaseHash);
      });
    });
  },
});

export default purchasesSlice.reducer;
