import { RootState } from "../store/store";
import { useAppSelector } from "./storeHooks";
import { useMemo } from "react";

const selectUser = (state: RootState) => state.user.user;
const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const usePermissionChecker = (permission: string) => {
  const user = useAppSelector(selectUser);
  const activeBusinessAccountHash = useAppSelector(selectActiveBusinessAccount)?.hash || "";

  return useMemo(() => {
    if (!user) {
      return false;
    }

    if (!permission) {
      return true;
    }

    const {
      attrs: { permissions },
    } = user;
    const businessPermission = `^(${activeBusinessAccountHash})\\.(${permission
      .replace(/\*/g, "\\*")
      .replace(/\./g, "|\\*)\\.(")}|\\*)$`;

    return permissions.some((userPermission) => userPermission.match(businessPermission) !== null);
  }, [activeBusinessAccountHash, permission, user]);
};

export default usePermissionChecker;
