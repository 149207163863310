import IRouteModel from "../../models/IRouteModel";
import PluginsPublic from "../../views/Plugins/PluginsPublic";
import MediaKitPublic from "../../views/MediaKit/MediaKitPublic";

const publicFooterRoutes: IRouteModel[] = [
  {
    path: "/plugins",
    name: "Plugins",
    component: PluginsPublic,
    layout: "",
    hidden: true,
  },
  {
    path: "/media-kit",
    name: "Media kit",
    component: MediaKitPublic,
    layout: "",
    hidden: true,
  },
];

export default publicFooterRoutes;
