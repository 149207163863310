/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-misused-promises */
import React, { ReactElement, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import FormSelect from "./FormFields/FormSelect";
import FormTextInput from "./FormFields/FormTextInput";
import { useAppDispatch } from "../hooks/storeHooks";
import fetchNotifications from "../store/thunks/notificationsThunk";
import useWrapFormToConsiderWhitespacesAsEmpty from "../hooks/reactHooksFormWhitespaceTrimHook";

interface IField {
  name: string;
  required: string | boolean;
  pattern: RegExp | undefined;
  minLength: number | undefined;
  type: string;
  label: string;
  inputMode: string | undefined;
  startAdornment: ReactElement | undefined;
  component: string;
  translationKeyPrefix: boolean | string;
  options: { value: string; label: string }[] | undefined;
  shrinkInputLabel?: boolean | undefined;
  compare?: string;
  compareError?: string;
  hideShowPassword?: boolean;
}

export interface IAccountActivationStep {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiRequest?: any;
  useDispatch?: boolean;
  fields?: IField[];
  okMessage?: string;
}

const Components = {
  input: FormTextInput,
  select: FormSelect,
};

interface IProps {
  form: IAccountActivationStep[];
  submitButtonText?: string;
}

const Form = ({ form, submitButtonText = "Save" }: IProps) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitResponseErrors, setSubmitResponseErrors] = useState([]);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const methodsOriginal = useForm();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useWrapFormToConsiderWhitespacesAsEmpty(methodsOriginal);

  const setNextStep = () => {
    if (form[step + 1]) {
      setStep(step + 1);
      setLoading(false);
    } else {
      void dispatch(fetchNotifications());
      setLoading(false);
    }
  };

  const onSubmit = (data: unknown) => {
    if (!form[step]) return;
    setLoading(true);
    const executionApiRequest = form[step].apiRequest;
    if (form[step].useDispatch) {
      void dispatch(executionApiRequest(data)).then(() => {
        setNextStep();
      });
    } else {
      executionApiRequest(data).then((response: { errors: React.SetStateAction<never[]> }) => {
        setSubmitResponseErrors([]);
        if (response && response.errors) {
          setSubmitResponseErrors(response.errors);
          setLoading(false);
        } else {
          setNextStep();
        }
      });
    }
  };

  return (
    <>
      {form[step]?.fields ? (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, maxWidth: 360 }}>
          {form[step].fields?.map(
            ({
              name,
              required,
              pattern,
              type,
              label,
              component,
              inputMode,
              startAdornment,
              minLength,
              options,
              translationKeyPrefix,
              shrinkInputLabel,
              compare,
              compareError,
              hideShowPassword,
            }: IField) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const CustomComponent = Components[component];
              return (
                <CustomComponent
                  key={name}
                  name={name}
                  control={control}
                  required={required}
                  pattern={pattern}
                  minLength={minLength}
                  type={type}
                  inputMode={inputMode}
                  startAdornment={startAdornment}
                  label={label}
                  errors={errors}
                  options={options}
                  translationKeyPrefix={translationKeyPrefix}
                  shrinkInputLabel={shrinkInputLabel}
                  hideShowPassword={hideShowPassword}
                  validate={
                    compare
                      ? (val: string) => {
                          if (watch(compare) !== val) {
                            return t(compareError);
                          }
                        }
                      : () => {}
                  }
                />
              );
            }
          )}
          <Controller
            name="submit"
            control={control}
            render={({ field }) => (
              <LoadingButton
                loading={loading}
                {...field}
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 8, mb: 2 }}
              >
                {t(submitButtonText)}
              </LoadingButton>
            )}
          />
        </Box>
      ) : null}
      {submitResponseErrors ? (
        <Box>
          {submitResponseErrors.map(({ error }) => (
            <Typography key={error} variant="body1" color="error" sx={{ mt: 1 }}>
              {t(error)}
            </Typography>
          ))}
        </Box>
      ) : null}
      {form[step].okMessage ? (
        <Box>
          <Typography variant="body1" sx={{ mt: 1, color: "success.main" }}>
            {t(form[step].okMessage || "")}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default Form;
