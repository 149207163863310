import enLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";

import en from "../translations/en.json";
import es from "../translations/es.json";

type LocaleMapOptions = {
  [key: string]: Locale;
};

const dateLocaleMap: LocaleMapOptions = {
  es: esLocale,
  en: enLocale,
};

const languages = [
  { lang: "es", icon: "Es", translations: es, dateLocale: dateLocaleMap.es },
  { lang: "en-GB", icon: "En", translations: en, dateLocale: dateLocaleMap.en },
];

export default languages;
