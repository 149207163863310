import React from "react";
//import i18n from "i18next";
//import { Box } from "@mui/material";
//import languages from "../utils/languages";
import { withTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  /* const changeLanguage = (language: string) => {
    void i18n.changeLanguage(language);
  }; */

  return <></>;
  /* <div>
      {languages.map(({ lang, icon }, index) => (
        <Box
          sx={{
            display: "inline",
            m: "0 8px",
            cursor: "pointer",
            borderBottom: i18n.language === lang ? "2px solid" : undefined,
            borderColor: i18n.language === lang ? "primary.main" : undefined,
            fontWeight: i18n.language === lang ? "bold" : undefined,
            color: i18n.language === lang ? "#000000" : "#777f89",
            fontSize: 18,
          }}
          key={lang}
          onClick={() => changeLanguage(lang)}
          onKeyDown={() => changeLanguage(lang)}
          role="link"
          tabIndex={index}
        >
          {icon}
        </Box>
      ))}
    </div> */
};

export default withTranslation()(LanguageSwitcher);
