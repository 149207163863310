import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import emailIcon from "../assets/img/e-mail-icon.svg";

const CheckEmail = ({ email }: { email: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <img width="80px" height="auto" src={emailIcon} alt="welcome" />
      </Box>
      <Typography sx={{ mt: 5 }} variant="h4">
        {t("Verify Email")}
      </Typography>
      <br />
      <Typography variant="body1">
        <Trans i18nKey="checkEmailToVerifyEmail" values={{ email }} components={{ strong: <strong /> }} />
      </Typography>
    </>
  );
};

export default CheckEmail;
