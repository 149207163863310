import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import { useAppSelector } from "../hooks/storeHooks";
import BusinessAccountDetailsEdit from "./Settings/BusinessAccount/BusinessAccountDetailsEdit";
import UserDetailsEdit from "./Settings/User/UserDetailsEdit";
import UserPasswordSet from "./Settings/User/UserPasswordSet";
import BusinessAccountReview from "./Settings/BusinessAccount/BusinessAccountReview";
import usePermissionChecker from "../hooks/usePermissionChecker";
import SignupDocuments from "./Merchant/SignupDocuments";

const selectNotifications = (state: RootState) => state.notifications.notifications;

const Dashboard = () => {
  const notifications = useAppSelector(selectNotifications);
  const navigate = useNavigate();

  const purchasesGetPermission = usePermissionChecker("get.business.purchases.accounts.*");
  const offlineOrderPostPermission = usePermissionChecker("post.business.purchases.accounts.*.offline");

  useEffect(() => {
    if (notifications.length === 0) {
      if (purchasesGetPermission) navigate("/admin/purchases");
      else if (offlineOrderPostPermission) navigate("/admin/offlineorder");
    }
  }, [navigate, notifications.length, offlineOrderPostPermission, purchasesGetPermission]);

  return (
    <>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div key={notification.hash}>
            {notification.title ? (
              <Typography variant="h4" color="text.primary" fontWeight={600} gutterBottom>
                {notification.title}
              </Typography>
            ) : null}
            {notification.body ? (
              <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                {notification.body}
              </Typography>
            ) : null}
            {notification.parameters.target_path === "/admin/settings/account" && <BusinessAccountDetailsEdit />}
            {notification.parameters.target_path === "/admin/settings/profile" && <UserDetailsEdit />}
            {notification.parameters.target_path === "/admin/settings/profile/password" && <UserPasswordSet />}
            {notification.parameters.target_path === "merchant/signup/documents" && <SignupDocuments />}
            {notification.parameters.target_path === "merchant/signup/kyc" && <BusinessAccountReview />}
            {/* TODO: this check must be updated - hash should not be used to determine view */}
            {notification.hash === "business-account-review-1" && <BusinessAccountReview />}
          </div>
        ))
      ) : (
        <div />
      )}
    </>
  );
};

export default Dashboard;
