/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from "axios";
// eslint-disable-next-line import/no-cycle
import { logout } from "../utils/authHelpers";
import getCookie from "../utils/getCookie";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 180000,
  withCredentials: true,
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      logout();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

API.interceptors.request.use((config) => {
  if (config?.headers?.common) {
    // https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.common["Accept-Language"] = getCookie("lang") || "es";
  }

  return config;
});

export default API;
