import React from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

interface IPurchaseStatusFilterProps {
  handleChange: (value: SelectChangeEvent<string[]>) => void;
  values: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const purchaseStatuses = ["completed", "ready_to_finance", "in_progress", "rejected", "declined", "canceled"];

const PurchaseStatusFilter = ({ values, handleChange }: IPurchaseStatusFilterProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "purchases" });
  return (
    <FormControl variant="standard" sx={{ width: 300 }}>
      <InputLabel id="status-filter-label">{t("Status")}</InputLabel>
      <Select
        labelId="status-filter-label"
        id="status-filter-checkbox"
        multiple
        value={values}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected.map((value) => t(value)).join(", ")}
        MenuProps={MenuProps}
      >
        {purchaseStatuses.map((purchaseStatus) => (
          <MenuItem key={purchaseStatus} value={purchaseStatus}>
            <Checkbox checked={values.indexOf(purchaseStatus) > -1} />
            <ListItemText primary={t(purchaseStatus)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PurchaseStatusFilter;
