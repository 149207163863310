import getCookie from "./getCookie";
import languages from "./languages";

const customLanguageDetector = {
  name: "customLanguageDetector",

  lookup(): string {
    const query = new URLSearchParams(window.location.search);
    const languageFromList = languages.find((language) => language.lang === query.get("lang"))?.lang;

    if (languageFromList) return languageFromList;

    const languageFromCookie = getCookie("lang");

    if (languageFromCookie) return languageFromCookie;

    const navigatorLanguage = languages.find((language) => language.lang === window.navigator.language)?.lang;

    if (navigatorLanguage) return navigatorLanguage;

    return "es";
  },

  cacheUserLanguage(lng: string): void {
    document.cookie = `lang=${lng}; max-age=31536000; SameSite=strict; path=/`;
    document.querySelector("html")?.setAttribute("lang", lng);
  },
};

export default customLanguageDetector;
