/* eslint-disable import/no-cycle */
import API from "./api";
import { setAuthToken } from "../utils/authHelpers";

const signupEmailURL = "/business/signup/email";
const signupEmailVerifyURL = "/business/signup/email/callback";
const loginEmailVerifyURL = "/business/auth/email/login";
const loginUserUsingEmailURL = "/business/auth/email/login";
const loginUserUsingPasswordURL = "/business/auth/password/login";
const authUserLogoutURL = "/business/auth/email/logout";

export interface IUserAuthResponse {
  attrs: {
    config: {
      language?: string;
    };
    permissions: Array<string>;
    identity?: string;
  };
  id: string;
  name: string;
  picture?: string;
  error?: string;
}

interface ISignupEmailVerifyParams {
  token: string;
}

interface ILoginUserUsingEmailParams {
  email: string;
}

interface ILoginUserUsingPasswordParams {
  email: string;
  password: string;
}

const signupEmail = async ({
  email,
  businessURL,
  countryISO,
}: {
  email: string;
  businessURL: string;
  countryISO: string;
}) => {
  return API.post(signupEmailURL, { email, business_url: businessURL, country_iso: countryISO })
    .then(({ data }: { data: { error: string } }) => {
      if (data.error) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const signupEmailVerify = ({ token }: ISignupEmailVerifyParams) => {
  return API.get(signupEmailVerifyURL, { params: { token } })
    .then(({ data }: { data: { data: IUserAuthResponse | null } }) => {
      if (data.data) {
        if (data.data.attrs.identity) {
          setAuthToken();
        }
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const loginUserUsingEmail = ({ email }: ILoginUserUsingEmailParams) => {
  return API.get(loginUserUsingEmailURL, { params: { email } })
    .then(({ data }: { data: { data: IUserAuthResponse | null } }) => {
      if (data.data) {
        if (data.data.attrs.identity) {
          setAuthToken();
        }

        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const loginUserUsingPassword = ({ email, password }: ILoginUserUsingPasswordParams) => {
  return API.get(loginUserUsingPasswordURL, { params: { user: email, passwd: password } })
    .then(({ data }: { data: IUserAuthResponse | null }) => {
      if (data) {
        if (data.attrs.identity) {
          setAuthToken();
        }
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const loginEmailVerify = ({ token }: ISignupEmailVerifyParams) => {
  return API.get(loginEmailVerifyURL, { params: { token } })
    .then(({ data }: { data: { data: IUserAuthResponse | null } }) => {
      if (data.data) {
        if (data.data.attrs.identity) {
          setAuthToken();
        }
        return data.data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export const revokeUserAuthTokenRequest = () => {
  return API.get(authUserLogoutURL)
    .then(({ data }: { data: IUserAuthResponse | null }) => {
      if (data) {
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export default signupEmail;
