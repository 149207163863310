import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import { fetchItems, fetchPurchasesFees } from "../../../store/thunks/purchasesThunk";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { IPurchase } from "../../../api/purchases";
import { formatDateWithTimeForDisplay } from "../../../utils/formatDate";
import Gandalf from "../../../components/Gandalf";
import { merchantStoreCheckoutPatchRequest } from "../../../api/merchant";
import { RootState } from "../../../store/store";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import usePermissionChecker from "../../../hooks/usePermissionChecker";
import PurchaseDelivered from "./PurchaseDelivered";
import PurchaseProducts from "./PurchaseProducts";
import PurchaseFees from "./PurchaseFees";

const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const PurchaseRow = ({ row, fetchTable }: { row: IPurchase; fetchTable: () => void }) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    number,
    first_name,
    status,
    shop_status_abbreviation,
    total_amount,
    transaction,
    fees,
    items,
    created_timestamp,
  } = row;
  /* eslint-enable */
  const [open, setOpen] = React.useState(false);
  const [markOrderDeliveredDialogOpen, setMarkOrderDeliveredDialogOpen] = React.useState(false);
  const activeBusinessAccount = useAppSelector(selectActiveBusinessAccount);
  const activeBusinessAccountCredentials = activeBusinessAccount?.credentials;
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation("translation", { keyPrefix: "purchases" });

  const purchasesFeeViewPermission = usePermissionChecker("get.business.purchases.accounts.*.fees");
  const offlineOrderViewPermission = usePermissionChecker("post.business.purchases.accounts.*.offline");

  const openRow = () => {
    setOpen(!open);
    if (!open) {
      if (purchasesFeeViewPermission) {
        void dispatch(fetchPurchasesFees([row]));
      }

      if (offlineOrderViewPermission) {
        void dispatch(fetchItems(row.order_hash));
      }
    }
  };

  const markOrderDelivered = () => {
    if (!activeBusinessAccountCredentials || loading) {
      return;
    }
    setMarkOrderDeliveredDialogOpen(false);
    const dataForAPI = {
      order: {
        status: "delivered",
        number: row.number,
        reference: row.reference,
      },
      client: {
        bundle_identifier: "com.fiizy.offline.backoffice",
      },
    };

    void merchantStoreCheckoutPatchRequest(dataForAPI, activeBusinessAccountCredentials)
      .then(() => {
        fetchTable();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const chipColor = (orderStatus: string) => {
    switch (orderStatus) {
      case "completed":
        return "success";
      case "declined":
        return "secondary";
      case "rejected":
        return "error";
      case "canceled":
        return "info";
      case "ready_to_finance":
      case "in_progress":
        return "warning";
      default:
        return "warning";
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {purchasesFeeViewPermission || offlineOrderViewPermission ? (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={openRow}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        <TableCell>{formatDateWithTimeForDisplay(new Date(created_timestamp))}</TableCell>
        <TableCell>{number}</TableCell>
        <TableCell>{first_name}</TableCell>
        <TableCell align="right">{total_amount}</TableCell>
        <TableCell align="right">
          <Chip label={t(status)} color={chipColor(status)} variant="outlined" />
        </TableCell>
        <Gandalf permission="get.business.purchases.accounts.*.transactions">
          <TableCell align="right">{transaction ? `${transaction.amount} ${transaction.currency}` : null}</TableCell>
          <TableCell align="right">
            {transaction ? formatDateWithTimeForDisplay(new Date(transaction.created_timestamp)) : null}
          </TableCell>
        </Gandalf>
        <Gandalf permission="post.business.purchases.accounts.*.offline">
          <PurchaseDelivered
            markOrderDeliveredDialogOpen={() => setMarkOrderDeliveredDialogOpen(true)}
            status={status}
            shop_status_abbreviation={shop_status_abbreviation}
          />
        </Gandalf>
      </TableRow>
      <Gandalf permission="post.business.purchases.accounts.*.offline">
        <PurchaseProducts items={items} open={open} />
      </Gandalf>
      <Gandalf permission="get.business.purchases.accounts.*.fees">
        <PurchaseFees fees={fees} open={open} />
      </Gandalf>
      <ConfirmationDialog
        open={markOrderDeliveredDialogOpen}
        title={t("mark_as_delivered_dialog_title")}
        content={t("mark_as_delivered_dialog_content")}
        yesCallback={markOrderDelivered}
        noCallback={() => setMarkOrderDeliveredDialogOpen(false)}
      />
    </>
  );
};

export default React.memo(PurchaseRow);
