export const transformToInt = {
  input: (value: string | number) => (Number.isNaN(value) ? "" : value.toString()),
  output: (e: { target: { value: string } }) => {
    const output = parseInt(e.target.value, 10);
    return Number.isNaN(output) ? 0 : output;
  },
};

export const transformToFloat = {
  input: (value: string | number) => (Number.isNaN(value) ? "" : value.toString()),
  output: (e: { target: { value: string } }) => {
    if (e.target.value.indexOf(".") === e.target.value.length - 1) return e.target.value;
    const output = Number(parseFloat(e.target.value).toFixed(2));
    return Number.isNaN(output) ? 0 : output;
  },
};
