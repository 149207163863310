/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import fetchNotifications from "../thunks/notificationsThunk";
import { Notification } from "../../api/notifications";

interface INotificationsSlice {
  notifications: Notification[];
}

const initialState = {
  notifications: [],
} as INotificationsSlice;

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export default notificationsSlice.reducer;
