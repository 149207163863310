import React, { useEffect, useState } from "react";

import { fetchActiveBusinessAccountDetailsUpdate } from "../../../store/thunks/businessAccountsThunk";
import Form, { IAccountActivationStep } from "../../../components/Form";
import { getMerchantIndustries } from "../../../api/businessAccount";

const formStep: IAccountActivationStep[] = [
  {
    apiRequest: fetchActiveBusinessAccountDetailsUpdate,
    useDispatch: true,
    fields: [
      {
        name: "merchant_industry",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "text",
        label: "Industry",
        component: "select",
        inputMode: "text",
        minLength: undefined,
        translationKeyPrefix: "industryOptions",
        options: [],
      },
      {
        name: "signup_turnover",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "text",
        label: "Turnover",
        component: "select",
        inputMode: "text",
        minLength: undefined,
        translationKeyPrefix: false,
        options: [
          { value: "10000-50000", label: "10 000 - 50 000€" },
          { value: "50000-100000", label: "50 000 - 100 000€" },
          { value: "100000-300000", label: "100 000 - 300 000€" },
          { value: "300000-500000", label: "300 000 - 500 000€" },
          { value: "500000-1000000", label: "500 000 - 1 000 000€" },
          { value: "1000000-3000000", label: "1 000 000 - 3 000 000€" },
          { value: "3000000+", label: "3 000 000+ €" },
        ],
      },
      {
        name: "signup_activity_since",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "text",
        label: "Activity since",
        component: "select",
        inputMode: "text",
        minLength: undefined,
        translationKeyPrefix: "activitySinceOptions",
        options: [
          { value: "less than 1 year", label: "Less than 1 year" },
          { value: "1 year", label: "1 year" },
          { value: "2 years", label: "2 years" },
          { value: "3 years", label: "3 years" },
          { value: "4 years", label: "4 years" },
          { value: "5 years", label: "5 years" },
          { value: "more than 5 years", label: "more than 5 years" },
        ],
      },
    ],
  },
];

const BusinessAccountDetailsEdit = () => {
  const [form, setForm] = useState<IAccountActivationStep[]>(formStep);

  useEffect(() => {
    void getMerchantIndustries().then((data) => {
      if (!data) return;
      const formFieldsCopy = { ...form[0] };
      const merchantIndustryField = formFieldsCopy.fields?.find((field) => field.name === "merchant_industry");
      if (merchantIndustryField) {
        merchantIndustryField.options = data.map((option) => ({ value: option.hash, label: option.code }));
      }
      setForm([formFieldsCopy]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Form form={form} />;
};

export default BusinessAccountDetailsEdit;
