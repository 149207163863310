import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { signupEmailVerify } from "../api/auth";
import useScript from "../hooks/useScript";
import useStyles from "../hooks/useStyles";
import processPostByChannelAndName from "../api/process";
import i18n from "i18next";
import Loader from "../components/Loader";

const Signup = () => {
  const [processLoaded, setProcessLoaded] = useState(false);
  const navigate = useNavigate();
  const { token, processSessionHash } = useParams();

  useEffect(() => {
    if (token) {
      return;
    }
    if (processSessionHash) {
      setProcessLoaded(true);
      return;
    }
    processPostByChannelAndName("MERCHANT_STORE_ONBOARDING", process.env.REACT_APP_CALC_FLOW_CHANNEL || "")
      .then((data) => {
        if (data?.attribution?.process_session_hash) {
          setProcessLoaded(true);

          navigate(`/process/${data.attribution.process_session_hash}/${Date.now().toString()}`);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useStyles(process.env.REACT_APP_CALC_STYLE_PATH || "");
  useScript(process.env.REACT_APP_CALC_JAVASCRIPT_PATH || "");

  useEffect(() => {
    if (token) {
      void signupEmailVerify({ token }).then((data) => {
        if (data === null) navigate("/");
        else navigate("/admin/dashboard");
      });
    }
  }, [navigate, token]);

  return (
    <div style={{ position: "initial" }}>
      {processLoaded ? (
        <div
          id="app"
          data-fiizy-app
          data-full-screen={"true"}
          data-api-url={process.env.REACT_APP_API_URL?.replace("/api/v1", "")}
          data-channel-hash={process.env.REACT_APP_CALC_FLOW_CHANNEL}
          data-language={i18n.language.split("-")[0]}
          data-locale={i18n.language}
          data-client-bundle-identifier={process.env.REACT_APP_CALC_FLOW_BUNDLE}
        ></div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Signup;
