import TableCell from "@mui/material/TableCell";
import React from "react";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

interface IPurchaseFees {
  open: boolean;
  heading: string;
  tableHeads: { value: string; align?: "inherit" | "left" | "center" | "right" | "justify" }[];
  tableRowData: {
    value: string | number;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    hash: string;
  }[][];
}

const PurchaseCollapsableRow = ({ open, heading, tableHeads, tableRowData }: IPurchaseFees) => {
  const { t } = useTranslation("translation", { keyPrefix: "purchases" });
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: open ? "" : "none" }} colSpan={7}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              {t(heading)}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableHeads.map((tableHead) => (
                    <TableCell key={tableHead.value} align={tableHead.align}>
                      {t(tableHead.value)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRowData &&
                  tableRowData.map((items, index) => (
                    <TableRow key={`${index}`}>
                      {items.map((item, itemIndex) => (
                        <TableCell key={`${itemIndex}${item.value}${item.hash}`} align={item.align}>
                          {item.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default PurchaseCollapsableRow;
