import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useInput from "../hooks/useInput";
import { loginEmailVerify } from "../api/auth";
import { useAppDispatch } from "../hooks/storeHooks";
import { loginUser } from "../store/thunks/userThunk";
import { setUser } from "../store/reducers/userReducer";

const Login = () => {
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword, reset: resetPassword } = useInput("");

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { t } = useTranslation();

  const { token } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      setLoading(true);
      void loginEmailVerify({ token }).then((data) => {
        if (data === null) {
          navigate("/");
        } else {
          dispatch(setUser(data));
          navigate("/admin/dashboard");
        }
        setLoading(false);
      });
    }
    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);

    void dispatch(loginUser({ email, password })).then((response) => {
      if (response) {
        setLoading(false);
        navigate("/admin/dashboard");
      } else {
        setShowError(true);
        setLoading(false);
        resetPassword();
      }
    });
  };

  return (
    <>
      <Typography component="h1" variant="h4">
        {t("Sign in")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }} color="text.secondary">
        {t("Don't have an account?")}{" "}
        <Link underline="none" href="https://form.jotform.com/222692053053349">
          {t("Sign up")}
        </Link>
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email Address")}
          name="email"
          type="email"
          autoComplete="email"
          value={bindEmail.value}
          onChange={bindEmail.onChange}
          variant="standard"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t("Password")}
          type="password"
          id="password"
          autoComplete="current-password"
          value={bindPassword.value}
          onChange={bindPassword.onChange}
          variant="standard"
        />
        {showError && (
          <Typography variant="body1" color="error" align="center" sx={{ mt: 1 }}>
            {t("Incorrect credentials, please try again!")}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: 5 }}
          disabled={loading || !email || !password}
        >
          {t("Sign in")}
        </Button>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <Link sx={{ fontSize: 14 }} underline="none" component={NavLink} to="login-with-email">
            {t("Trouble logging in?")}
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default Login;
