import React, { useMemo } from "react";
import { IPurchaseFee } from "../../../api/purchases";
import { formatDateWithTimeForDisplay } from "../../../utils/formatDate";
import PurchaseCollapsableRow from "./PurchaseCollapsableRow";

interface IPurchaseFees {
  fees: IPurchaseFee[];
  open: boolean;
}

const PurchaseFees = ({ fees, open }: IPurchaseFees) => {
  const tableHeadsData = () => {
    return [
      { value: "Amount" },
      { value: "Fee type" },
      { value: "Created" },
      { value: "Value date timestamp", align: "right" as const },
    ];
  };

  const tableRowData = useMemo(() => {
    if (!fees) return [];
    return fees.map(
      ({ amount, created_timestamp: transactionTimestamp, value_date_timestamp, currency, fee_type, order_hash }) => [
        { value: `${amount} ${currency}`, hash: order_hash },
        { value: fee_type, hash: order_hash },
        { value: formatDateWithTimeForDisplay(new Date(transactionTimestamp)), hash: order_hash },
        {
          value: formatDateWithTimeForDisplay(new Date(value_date_timestamp)),
          align: "right" as const,
          hash: order_hash,
        },
      ]
    );
  }, [fees]);

  return (
    <PurchaseCollapsableRow
      open={open}
      heading={"Fees"}
      tableHeads={tableHeadsData()}
      tableRowData={tableRowData}
    ></PurchaseCollapsableRow>
  );
};

export default PurchaseFees;
