import { createAsyncThunk } from "@reduxjs/toolkit";
import { authUserInfo } from "../../api/user";
import { loginUserUsingPassword } from "../../api/auth";

export const loginUser = createAsyncThunk("user/login", async (payload: { email: string; password: string }) => {
  const response = await loginUserUsingPassword(payload);
  return response;
});

const fetchUserStatus = createAsyncThunk("user/fetchStatus", async (_, { getState }) => {
  const { user } = getState().user;

  if (user) return user;

  const response = await authUserInfo();
  return response;
});

export default fetchUserStatus;
