import React, { useState } from "react";
import { SelectChangeEvent } from "@mui/material";

interface IUseInput {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  reset: () => void;
  bind: {
    value: string;
    onChange: (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
}

const useInput = (initialValue: string): IUseInput => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        if (typeof event === "string") setValue(event);
        else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(event.target.value);
        }
      },
    },
  };
};

export default useInput;
