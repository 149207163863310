import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

interface IConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  yesCallback: () => void;
  noCallback: () => void;
}

const ConfirmationDialog = ({ open, title, content, yesCallback, noCallback }: IConfirmationDialogProps) => {
  const { t } = useTranslation("translation");

  return (
    <Dialog onClose={() => noCallback()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => noCallback()} variant="contained" endIcon={<CloseIcon />}>
          {t("no")}
        </Button>
        <Button variant="outlined" onClick={() => yesCallback()} autoFocus>
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
