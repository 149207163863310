import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPurchaseDelivered {
  markOrderDeliveredDialogOpen: () => void;
  status: string;
  shop_status_abbreviation: string;
}

const PurchaseDelivered = ({ markOrderDeliveredDialogOpen, status, shop_status_abbreviation }: IPurchaseDelivered) => {
  const { t } = useTranslation("translation", { keyPrefix: "purchases" });

  return (
    <TableCell align="right">
      {status === "completed" ? (
        <>
          {shop_status_abbreviation === "delivered" ? (
            <Chip label={t("delivered")} color="info" variant="outlined" />
          ) : (
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={markOrderDeliveredDialogOpen}
              sx={{ fontSize: 12 }}
              endIcon={<CheckIcon />}
            >
              {t("mark_as_delivered")}
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </TableCell>
  );
};

export default PurchaseDelivered;
