import {
  endOfMonth,
  endOfToday,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  subDays,
  subMonths,
} from "date-fns";

export interface IPeriod {
  label: string;
  start: Date;
  end: Date;
}

const periods = [
  {
    label: "Today",
    start: startOfToday(),
    end: endOfToday(),
  },
  {
    label: "Yesterday",
    start: startOfYesterday(),
    end: endOfYesterday(),
  },
  {
    label: "Last 7 Days",
    start: subDays(startOfToday(), 7),
    end: subDays(endOfToday(), 1),
  },
  {
    label: "Last 30 Days",
    start: subDays(startOfToday(), 30),
    end: subDays(endOfToday(), 1),
  },
  {
    label: "This Month",
    start: startOfMonth(startOfToday()),
    end: endOfMonth(endOfToday()),
  },
  {
    label: "Last Month",
    start: startOfMonth(subMonths(startOfToday(), 1)),
    end: endOfMonth(subMonths(endOfToday(), 1)),
  },
  {
    label: "Custom period",
    start: startOfToday(),
    end: endOfToday(),
  },
];

export default periods;
