import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import welcome from "../../../assets/img/dashboard/welcome.png";
import { useAppSelector } from "../../../hooks/storeHooks";
import { RootState } from "../../../store/store";

const selectUser = (state: RootState) => state.user.user;

const BusinessAccountReview = () => {
  const user = useAppSelector(selectUser);
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: 620 }}>
      <Typography variant="h4" color="text.primary" fontWeight={600} gutterBottom>
        {t("Welcome")}
        {user ? `, ${user.name}` : null}!
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color="text.secondary" gutterBottom>
        {t(
          "To finalize account activation and get credentials for plugin activation, our support will contact your shortly!"
        )}
      </Typography>
      <Box sx={{ mt: { xs: 3, md: 5 } }}>
        <img width="100%" height="auto" src={welcome} alt="welcome" />
      </Box>
      <Typography sx={{ mt: { xs: 3, md: 5 } }} variant="body1" color="text.secondary" gutterBottom>
        {t("Sincerely")},
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        {t("Fiizy Support Team")}
      </Typography>
    </Box>
  );
};

export default BusinessAccountReview;
