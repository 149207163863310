import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormTextInput from "../../../components/FormFields/FormTextInput";
import { transformToFloat, transformToInt } from "../../../utils/transformers";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ILineItem } from "../OfflineOrder";
import { Control, FieldValues } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import Button from "@mui/material/Button";

interface IOfflineOrderProducts {
  errors: FieldErrors<FieldValues>;
  control: Control<{
    lineItems: ILineItem[];
    customer: { phoneNumber: string };
    client: { bundle_identifier: string };
  }>;
  fields: ILineItem[];
  remove: (index: number) => void;
  productsTotalSum: () => string;
  addNewProduct: () => void;
}

const OfflineOrderProducts = ({
  errors,
  control,
  fields,
  remove,
  productsTotalSum,
  addNewProduct,
}: IOfflineOrderProducts) => {
  const { t } = useTranslation("translation", { keyPrefix: "offlineOrder" });

  return (
    <Box sx={{ maxWidth: 900 }}>
      {fields.map((product, index) => (
        <Box
          key={product.id}
          sx={{
            "& > :not(style)": { m: 1 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormTextInput
            required="This is required."
            pattern={/^[\s\S]{2,}$/}
            errors={errors}
            name={`lineItems.${index}.name`}
            label={t("Product name")}
            type="text"
            control={control}
            inputMode="text"
            fieldArrayInputIndex={index}
            fieldArrayKey="lineItems"
            fieldName="name"
          />
          <FormTextInput
            required="This is required."
            errors={errors}
            name={`lineItems.${index}.price`}
            label={t("Price")}
            type="text"
            control={control}
            inputMode="numeric"
            transform={transformToFloat}
            fieldArrayInputIndex={index}
            fieldArrayKey="lineItems"
            fieldName="price"
          />
          <FormTextInput
            required="This is required."
            errors={errors}
            name={`lineItems.${index}.quantity`}
            label={t("Quantity")}
            type="text"
            control={control}
            inputMode="numeric"
            transform={transformToInt}
            fieldArrayInputIndex={index}
            fieldArrayKey="lineItems"
            fieldName="quantity"
          />
          {fields.length > 1 ? (
            <IconButton aria-label="delete" onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </Box>
      ))}
      <Box sx={{ textAlign: "right", marginRight: fields.length > 1 ? "63px" : 1 }}>
        {t("Total sum")}: {productsTotalSum()}
      </Box>
      <Button type="button" variant="outlined" color="secondary" size="small" sx={{ mt: 2 }} onClick={addNewProduct}>
        {t("Add product")} +
      </Button>
    </Box>
  );
};

export default OfflineOrderProducts;
