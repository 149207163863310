import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IPurchase,
  IPurchaseSearchParams,
  purchases,
  purchasesFees,
  purchasesItems,
  purchasesTransactions,
} from "../../api/purchases";

export const fetchPurchasesTransactions = createAsyncThunk(
  "purchases/fetchPurchasesTransactions",
  async (payload: IPurchase[], { getState }) => {
    const { activeBusinessAccount } = getState().business;

    if (!activeBusinessAccount) return [];

    const purchasesHashesString = payload.map((purchase) => purchase.order_hash).join(",");

    return purchasesTransactions(purchasesHashesString, activeBusinessAccount.hash);
  }
);

export const fetchPurchasesFees = createAsyncThunk(
  "purchases/fetchPurchasesFees",
  async (payload: IPurchase[], { getState }) => {
    const { activeBusinessAccount } = getState().business;

    if (!activeBusinessAccount) return [];

    const purchasesHashesString = payload.map((purchase) => purchase.order_hash).join(",");

    return purchasesFees(purchasesHashesString, activeBusinessAccount.hash);
  }
);

const fetchPurchases = createAsyncThunk(
  "purchases/fetchPurchases",
  async (
    {
      limit,
      offset,
      search,
    }: {
      limit: number;
      offset: number;
      search?: IPurchaseSearchParams;
    },
    { getState }
  ) => {
    const { activeBusinessAccount } = getState().business;

    if (!activeBusinessAccount) return [];

    const response = await purchases({ limit, offset, businessAccountHash: activeBusinessAccount.hash, search });

    return response;
  }
);

export const fetchItems = createAsyncThunk("purchases/fetchItems", async (orderHash: string, { getState }) => {
  const { activeBusinessAccount } = getState().business;

  if (!activeBusinessAccount) return [];

  const response = await purchasesItems({
    businessAccountHash: activeBusinessAccount.hash,
    orderHash: orderHash,
  });

  return response;
});

export default fetchPurchases;
