/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchActiveBusinessAccountDetailsUpdate,
  // fetchActiveBusinessAccountInfo,
  fetchBusinessAccounts,
} from "../thunks/businessAccountsThunk";
import { IBusinessAccount } from "../../api/businessAccount";
import {
  getActiveBusinessAccountHashStorage,
  setActiveBusinessAccountStorage,
} from "../../utils/activeBusinessAccountCookie";

interface IBusinessAccountsInitialState {
  accounts: IBusinessAccount[];
  activeBusinessAccount: IBusinessAccount | null;
}

const initialState: IBusinessAccountsInitialState = {
  accounts: [],
  activeBusinessAccount: null,
};

export const businessAccountsSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setActiveBusinessAccount(state, action) {
      const activeBusinessAccount = state.accounts.find((account) => account.hash === action.payload) || null;
      if (activeBusinessAccount) {
        state.activeBusinessAccount = activeBusinessAccount;
        setActiveBusinessAccountStorage(activeBusinessAccount.hash);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload || [];
      if (state.accounts.length > 0) {
        let activeBusinessAccount = state.accounts[0];

        if (getActiveBusinessAccountHashStorage()) {
          const businessAccountFromCookie = state.accounts.find(
            (account) => account.hash === getActiveBusinessAccountHashStorage()
          );
          if (businessAccountFromCookie) {
            activeBusinessAccount = businessAccountFromCookie;
          }
        }

        state.activeBusinessAccount = activeBusinessAccount;
        setActiveBusinessAccountStorage(activeBusinessAccount.hash);
      }
    });
    builder.addCase(fetchActiveBusinessAccountDetailsUpdate.fulfilled, (state, action) => {
      if (action.payload) {
        state.activeBusinessAccount = action.payload;
      }
    });
    /*
    builder.addCase(fetchActiveBusinessAccountInfo.fulfilled, (state, action) => {
      if (action.payload) {
        // state.activeBusinessAccount = action.payload;
      }
    }); */
  },
});

export const { setActiveBusinessAccount } = businessAccountsSlice.actions;

export default businessAccountsSlice.reducer;
