import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import i18n from "i18next";
import prestashop from "../assets/img/logo-prestashop.png";
import woocommerce from "../assets/img/logo-woocommerce.png";
import magento from "../assets/img/logo-magento.svg";

interface IPlugin {
  name: string;
  url: string;
  icon: string;
  pluginShopLink?: { "en-GB": string; [lang: string]: string };
}

const Plugins = () => {
  const { t } = useTranslation();

  const fileTimestamp = Date.now();

  const plugins: IPlugin[] = [
    {
      name: "Prestashop",
      url: `https://cdn.fiizy.com/plugins/prestashop-fiizy.zip?ts=${fileTimestamp}`,
      icon: prestashop,
      pluginShopLink: {
        "en-GB":
          "https://addons.prestashop.com/en/payments-gateways-prestashop-modules/85819-fiizy-pay-later-multi-lender-payment-gateway.html",
        es: "https://addons.prestashop.com/es/pagos-prestashop-modulos/85819-pasarela-bnpl-multiprestamista-fiizy.html",
      },
    },
    {
      name: "Woocommerce",
      url: `https://cdn.fiizy.com/plugins/woocommerce-fiizy.zip?ts=${fileTimestamp}`,
      icon: woocommerce,
      pluginShopLink: {
        "en-GB": "https://wordpress.org/plugins/fiizy-pay-later-multi-lender-payment-gateway-for-woocommerce/",
      },
    },
    {
      name: "Magento",
      url: `https://cdn.fiizy.com/plugins/magento-fiizy.zip?ts=${fileTimestamp}`,
      icon: magento,
    },
  ];

  return (
    <>
      <Typography component="h1" variant="h4">
        {t("Plugins")}
      </Typography>
      {plugins.map(({ url, icon, name, pluginShopLink }) => (
        <Box
          key={name}
          sx={{
            mt: 4,
            padding: "16px 16px 16px 24px",
            borderRadius: "16px",
            border: "solid 1px #ccd4db",
            backgroundColor: "#f6f8f9",
            width: { xs: "100%", md: "fit-content" },
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", md: "none" },
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              mr: { xs: "16px", md: "140px" },
              height: "auto",
              width: { xs: 120, md: 190 },
            }}
          >
            <img src={icon} alt={name} width="100%" height="100%" />
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "block" }, alignItems: "center", flexDirection: "column" }}>
            <Link sx={{ wordBreak: "space", mr: { xs: 0, sm: "24px" }, mb: { xs: "8px", md: 0 } }} href={url}>
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  padding: { xs: "10px 16px", md: "12px 24px" },
                  bgcolor: "#ffffff",
                  fontSize: { xs: "14px", md: "inherit" },
                }}
              >
                {t("Download")}
              </Button>
            </Link>
            {pluginShopLink ? (
              <Link sx={{ wordBreak: "space" }} href={pluginShopLink[i18n.language] || pluginShopLink["en-GB"]}>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    padding: { xs: "10px 16px", md: "12px 24px" },
                    bgcolor: "#ffffff",
                    fontSize: { xs: "14px", md: "inherit" },
                  }}
                >
                  {t("Plugin store")}
                </Button>
              </Link>
            ) : null}
          </Box>
        </Box>
      ))}
      <Box sx={{ mt: 4 }}>
        <Typography component="h1" variant="h5">
          PHP SDK
        </Typography>
        <Link href="https://github.com/Fiizy/php-sdk">https://github.com/Fiizy/php-sdk</Link>
      </Box>
    </>
  );
};

export default Plugins;
