import React from "react";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";

const TermsAndPrivacyPolicyText = () => {
  return (
    <Typography variant="body2" sx={{ mt: 5 }} fontSize={14} color="text.secondary">
      <Trans i18nKey="termsAndPrivacyAccept">
        By continuing you agree with the{" "}
        <Link underline="none" component={NavLink} to="/privacy-policy">
          Privacy Policy
        </Link>
        {" and "}
        <Link underline="none" component={NavLink} to="/terms-and-conditions">
          Terms of Use
        </Link>
      </Trans>
    </Typography>
  );
};

export default TermsAndPrivacyPolicyText;
