import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import merchantStoreCheckoutRequest from "../../api/merchant";
import { RootState } from "../../store/store";
import { useAppSelector } from "../../hooks/storeHooks";
import OfflineOrderQrCode from "./Components/OfflineOrderQrCode";
import OfflineOrderProducts from "./Components/OfflineOrderProducts";
import OfflineOrderDetails from "./Components/OfflineOrderDetails";

export interface ILineItem {
  id?: number;
  name: string;
  quantity: number;
  price: number;
}

interface IFormData {
  lineItems: ILineItem[];
  customer: { phoneNumber: string };
  client: {
    bundle_identifier: string;
  };
}

const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const OfflineOrder = () => {
  const { t } = useTranslation("translation", { keyPrefix: "offlineOrder" });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<{ error: string }[]>([]);
  const [qrCode, setQrCode] = useState({ show: false, qrCodeData: "" });

  const activeBusinessAccount = useAppSelector(selectActiveBusinessAccount);
  const activeBusinessAccountCredentials = activeBusinessAccount?.credentials;

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      lineItems: [{ name: "", quantity: 1, price: 0.0 }],
      customer: { phoneNumber: "" },
      client: {
        bundle_identifier: "com.fiizy.offline.backoffice",
      },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "lineItems",
  });

  const products = useWatch({ control, name: "lineItems" });

  const addNewProduct = () => {
    append({ name: "", quantity: 1, price: 0.0 });
  };

  const removeQrCode = () => {
    setQrCode({ show: false, qrCodeData: "" });
  };

  const onProductsFormSubmit = (data: unknown) => {
    if (!activeBusinessAccountCredentials || loading) {
      return;
    }
    const { customer, client, lineItems } = data as IFormData;
    const dataForAPI = {
      order: {
        line_items: lineItems,
      },
      customer: {
        phone_number: customer.phoneNumber,
      },
      client,
    };
    setLoading(true);
    void merchantStoreCheckoutRequest(dataForAPI, activeBusinessAccountCredentials)
      .then((response) => {
        if (response?.errors) {
          setFormErrors(response.errors);
        } else if (response?.data?.redirect_url) {
          setQrCode({ show: true, qrCodeData: response.data.redirect_url });
          setFormErrors([]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setQrCode({ show: false, qrCodeData: "" });
      });
  };

  const productsTotalSum = () => {
    return products
      .reduce((a, b) => parseFloat(a.toString()) + parseFloat((b.price * b.quantity || 0).toString()), 0)
      .toFixed(2);
  };

  return (
    <>
      {qrCode.show ? (
        <OfflineOrderQrCode
          removeQrCode={removeQrCode}
          qrCodeData={qrCode.qrCodeData}
          phoneNumber={getValues("customer.phoneNumber")}
        />
      ) : (
        <>
          <Typography component="h1" variant="h4">
            {t("Products")}
          </Typography>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Box sx={{ mt: 4 }} component="form" noValidate onSubmit={handleSubmit(onProductsFormSubmit)}>
            <OfflineOrderProducts
              errors={errors}
              control={control}
              fields={fields as unknown as ILineItem[]}
              remove={remove}
              productsTotalSum={productsTotalSum}
              addNewProduct={addNewProduct}
            />
            <OfflineOrderDetails errors={errors} control={control} />
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 2, maxWidth: 300 }}
            >
              {t("Save")}
            </LoadingButton>
            <Box>
              {formErrors.length > 0
                ? formErrors.map(({ error }) => (
                    <Typography key={error} variant="body1" color="error" sx={{ mt: 1 }}>
                      {t(error)}
                    </Typography>
                  ))
                : null}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default OfflineOrder;
