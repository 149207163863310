import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import customLanguageDetector from "./utils/customLanguageDetector";
import languages from "./utils/languages";
interface ITranslations {
  [key: string]: { [key: string]: string };
}
// TODO: https://react.i18next.com/guides/multiple-translation-files)
const resources = Object.assign(
  {},
  ...languages.map(({ lang, translations }) => ({ [lang]: translations }))
) as ITranslations;

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    resources,
    detection: {
      order: ["customLanguageDetector"],
      caches: ["customLanguageDetector"],
    },
    fallbackLng: "es",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    nsSeparator: false,
  });
export default i18n;
