import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const PrestashopVideoGuide = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="h1" variant="h4">
        {t("How to install Prestashop plugin in 30 seconds")}
      </Typography>
      <Box sx={{ mt: 4 }}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls width="100%">
          <source src="https://cdn.fiizy.com/guides/prestashop/prestashop.mp4" type="video/mp4" />
        </video>
      </Box>
    </>
  );
};

export default PrestashopVideoGuide;
