import API from "./api";

const merchantStoreCheckoutUrl = "/merchant/store/checkout";

interface IMerchantStoreCheckout {
  order: {
    reference?: string;
    status?: string;
    line_items?: {
      name: string;
      description?: string;
      quantity: number;
      price: number;
    }[];
  };
  customer?: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    phone_number: string;
    date_of_birth?: string;
  };
  client?: {
    bundle_identifier: string;
  };
}

interface IMerchantCheckoutResponse {
  data: {
    data?: { redirect_url: string };
    errors?: { error: string }[];
    success: boolean;
  };
}

const merchantStoreCheckoutRequest = (
  formData: IMerchantStoreCheckout,
  credentials: { username: string; password: string }
) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return API.post(`${process.env.REACT_APP_API_URL}${merchantStoreCheckoutUrl}`.replace("v1", "v2"), formData, {
    auth: credentials,
  })
    .then(({ data }: IMerchantCheckoutResponse) => {
      return data;
    })
    .catch(({ response: { data } }: { response: IMerchantCheckoutResponse }) => {
      return data;
    });
};

export const merchantStoreCheckoutPatchRequest = (
  formData: IMerchantStoreCheckout,
  credentials: { username: string; password: string }
) => {
  return API.patch(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${process.env.REACT_APP_API_URL}/merchant/store/checkout/${formData.order.reference}`.replace("v1", "v2"),
    formData,
    {
      auth: credentials,
    }
  )
    .then(({ data }: IMerchantCheckoutResponse) => {
      return data;
    })
    .catch(({ response: { data } }: { response: IMerchantCheckoutResponse }) => {
      return data;
    });
};

export default merchantStoreCheckoutRequest;
