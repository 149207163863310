/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { endOfToday, startOfToday, subDays } from "date-fns";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import fetchPurchases, { fetchPurchasesTransactions } from "../../store/thunks/purchasesThunk";
import { IPurchaseSearchParams, TPurchaseStatus } from "../../api/purchases";
import Loader from "../../components/Loader";
import debounce from "../../utils/debounce";
import { IPeriod } from "../../utils/periods";
import { formatDateForApi } from "../../utils/formatDate";
import DatepickerWithPeriods from "../../components/Date/DatepickerWithPeriods";
import PurchaseRow from "./Components/PurchaseRow";
import PurchaseStatusFilter from "./Components/PurchaseStatusFilter";
import Gandalf from "../../components/Gandalf";
import usePermissionChecker from "../../hooks/usePermissionChecker";
import { unwrapResult } from "@reduxjs/toolkit";

const selectPurchases = (state: RootState) => state.purchases.purchases;
const selectPurchasesLoading = (state: RootState) => state.purchases.loading;
const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const rowsPerPageCount = 15;

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  loading: boolean;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange, loading } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0 || loading} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0 || loading} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || loading}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

const Purchases = () => {
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [period, setPeriod] = React.useState<IPeriod>({
    label: "7 Days",
    start: subDays(startOfToday(), 6),
    end: endOfToday(),
  });
  const [statusFilter, setStatusFilter] = React.useState<string[]>(["completed"]);
  const purchasesList = useAppSelector(selectPurchases);
  const loading = useAppSelector(selectPurchasesLoading);
  const activeBusinessAccountHash = useAppSelector(selectActiveBusinessAccount)?.hash || null;
  const dispatch = useAppDispatch();

  const transactionsPermission = usePermissionChecker("get.business.purchases.accounts.*.transactions");
  const purchasesFeeViewPermission = usePermissionChecker("get.business.purchases.accounts.*.fees");
  const offlineOrderViewPermission = usePermissionChecker("post.business.purchases.accounts.*.offline");

  const eventHandler = (searchParam?: IPurchaseSearchParams) => {
    void dispatch(
      fetchPurchases({
        limit: rowsPerPageCount + 1,
        offset: page * rowsPerPageCount,
        search: searchParam,
      })
    ).then((response) => {
      const purchases = unwrapResult(response);
      if (transactionsPermission && purchases?.length > 0) {
        void dispatch(fetchPurchasesTransactions(purchases));
      }
    });
  };

  const fetchTable = () => {
    eventHandler({
      number: search,
      start_timestamp: formatDateForApi(period.start),
      end_timestamp: formatDateForApi(period.end),
      status: statusFilter.join(",") as TPurchaseStatus,
    });
  };

  const debouncedChangeHandler = React.useMemo(
    () => debounce((searchParams?: IPurchaseSearchParams) => eventHandler(searchParams), 500, false),
    [transactionsPermission]
  );

  useEffect(() => {
    debouncedChangeHandler({
      number: search,
      start_timestamp: formatDateForApi(period.start),
      end_timestamp: formatDateForApi(period.end),
      status: statusFilter.join(","),
    });
  }, [search, period, statusFilter]);

  useEffect(() => {
    debouncedChangeHandler({
      start_timestamp: formatDateForApi(period.start),
      end_timestamp: formatDateForApi(period.end),
      status: statusFilter.join(","),
    });

    return () => {
      debouncedChangeHandler.clear();
      setSearch("");
    };
  }, [activeBusinessAccountHash]);

  const { t } = useTranslation("translation", { keyPrefix: "purchases" });

  const dateChanged = (newPeriod: IPeriod) => {
    setPage(0);
    setPeriod(newPeriod);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    void dispatch(
      fetchPurchases({
        limit: rowsPerPageCount + 1,
        offset: newPage * rowsPerPageCount,
        search: {
          number: search,
          start_timestamp: formatDateForApi(period.start),
          end_timestamp: formatDateForApi(period.end),
          status: statusFilter.join(",") as TPurchaseStatus,
        },
      })
    ).then((response) => {
      const purchases = unwrapResult(response);
      if (transactionsPermission && purchases?.length > 0) {
        void dispatch(fetchPurchasesTransactions(purchases));
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (page !== 0) setPage(0);
    setSearch(event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<typeof statusFilter>) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <TableContainer>
      <Box
        sx={{
          pt: 2,
          pb: 2,
          display: "grid",
          gridGap: 16,
          gridTemplateColumns: { xs: "1fr", md: "auto auto 1fr" },
        }}
      >
        <TextField
          sx={{ width: "240px" }}
          id="search"
          label={t("Search number")}
          variant="standard"
          value={search}
          onChange={handleSearchChange}
        />
        <DatepickerWithPeriods dateChangeHandler={dateChanged} defaultPeriod={period} />
        <PurchaseStatusFilter handleChange={handleStatusFilterChange} values={statusFilter} />
      </Box>
      <Box sx={{ position: "relative" }}>
        {loading && (
          <Box sx={{ position: "absolute", width: "100%", height: "100%", bgcolor: "#fff", opacity: 0.5, zIndex: 10 }}>
            <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
              <Loader color="#000000" size={50} />
            </Box>
          </Box>
        )}
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {purchasesFeeViewPermission || offlineOrderViewPermission ? <TableCell /> : null}
              <TableCell>{t("Created")}</TableCell>
              <TableCell>{t("Order number")}</TableCell>
              <TableCell>{t("customer_first_name")}</TableCell>
              <TableCell align="right">{t("Total amount")}</TableCell>
              <TableCell align="right">{t("Status")}</TableCell>
              <Gandalf permission="get.business.purchases.accounts.*.transactions">
                <TableCell align="right">{t("Transaction amount")}</TableCell>
                <TableCell align="right">{t("Transaction time")}</TableCell>
              </Gandalf>
              <Gandalf permission="post.business.purchases.accounts.*.offline">
                <TableCell align="right"></TableCell>
              </Gandalf>
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: "relative" }}>
            {purchasesList.slice(0, rowsPerPageCount).map((purchase) => (
              <PurchaseRow key={purchase.order_hash} row={purchase} fetchTable={fetchTable} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={page * rowsPerPageCount + purchasesList.length}
                rowsPerPage={rowsPerPageCount}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={({ count, page: pageNumber, rowsPerPage, onPageChange }) => (
                  <TablePaginationActions
                    count={count}
                    page={pageNumber}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onPageChange}
                    loading={loading}
                  />
                )}
                showFirstButton
                rowsPerPageOptions={[{ value: -1, label: "" }]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default Purchases;
