import { format } from "date-fns";
import i18n from "i18next";
import languages from "./languages";
import logger from "./logger";

export function getDateLocale(): Locale | undefined {
  const language = languages.find(({ lang }) => lang === i18n.language)?.dateLocale;

  if (!language) {
    logger.warn("Date locale is missing for user language", { lang: i18n.language });
  }

  return language;
}

export function formatDateForApi(date: Date): string {
  return format(date, "yyyy-MM-dd HH:mm:ss");
}

export function formatDateForDisplay(date: Date): string {
  // P = 04/29/1453
  return format(date, "P", { locale: getDateLocale() });
}

export function formatDateWithTimeForDisplay(date: Date): string {
  // PP = 04/29/1453, 12:00 AM
  return format(date, "Pp", { locale: getDateLocale() });
}
