import API from "./api";

interface Attribution {
  process_session_hash?: string;
}

export interface Form {
  attribution?: Attribution;
}

const processPostByChannelAndName = (processName: string, channel: string) => {
  const form = {
    form: {},
    attribution: { channel },
  };

  return API.post(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${process.env.REACT_APP_API_URL}/processes/${processName}/channels/${channel}`.replace("v1", "v2"),
    form
  )
    .then(({ data }: { data: Form }) => {
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
      }

      return null;
    })
    .catch(() => {
      return null;
    });
};

export default processPostByChannelAndName;
