import React from "react";
import i18n from "i18next";
import PrivacyPolicyMarkdownES from "./es/PrivacyPolicy.md";
import PrivacyPolicyMarkdownEN from "./en/PrivacyPolicy.md";
import Markdown from "../../components/Markdown";
import { withTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const getCorrectDocument = () => {
    switch (i18n.language) {
      case "en-GB":
        return <Markdown>{PrivacyPolicyMarkdownEN}</Markdown>;
      case "es":
      default:
        return <Markdown>{PrivacyPolicyMarkdownES}</Markdown>;
    }
  };

  return getCorrectDocument();
};

export default withTranslation()(PrivacyPolicy);
