/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { StaticDatePicker, PickersDay, PickersDayProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import "./datepicker.scss";
import { getDateLocale } from "../../utils/formatDate";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const DatePicker = ({
  start,
  end,
  pickerDateChange,
}: {
  start: Date | null;
  end: Date | null;
  pickerDateChange: (value: Date) => void;
}) => {
  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!start || !end) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <PickersDay disableMargin {...pickersDayProps} />;
    }

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale()}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={start}
        onChange={(newValue) => {
          pickerDateChange(newValue || new Date());
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params: TextFieldProps) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
