import { Logger } from "@fiizy/utils/dist/logger";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

const logger: Logger = new Logger({
  releaseStage: process.env.REACT_APP_BUILD_ENV || "development",
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
  appVersion: process.env.REACT_APP_VERSION || "0.0.1",
  // that [1] is paylands specific error for breadcrumbs
  // as it contains sensitive data we ignore it
  // filters doc: https://docs.bugsnag.com/platforms/javascript/configuration-options/#filters
  filters: ["[1]", /^password$/i, /^cc_/],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  plugins: [new BugsnagPluginReact(React)],
});

export default logger;
