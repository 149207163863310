import React from "react";
import { InputAdornment } from "@mui/material";

import Form, { IAccountActivationStep } from "../../../components/Form";
import { settingsProfileEdit } from "../../../api/user";

const formStep: IAccountActivationStep[] = [
  {
    apiRequest: settingsProfileEdit,
    useDispatch: false,
    fields: [
      {
        name: "firstName",
        required: "This is required.",
        pattern: /^[A-Za-z¡ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝWÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŒœŠšŸŽžƒśźż -]{2,}$/,
        type: "text",
        label: "First Name",
        component: "input",
        inputMode: "text",
        startAdornment: undefined,
        minLength: undefined,
        options: undefined,
        translationKeyPrefix: false,
      },
      {
        name: "lastName",
        required: "This is required.",
        pattern: /^[A-Za-z¡ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝWÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŒœŠšŸŽžƒśźż -]{2,}$/,
        type: "text",
        label: "Last Name",
        component: "input",
        inputMode: "text",
        startAdornment: undefined,
        minLength: undefined,
        options: undefined,
        translationKeyPrefix: false,
      },
      {
        name: "phone",
        required: "This is required.",
        pattern: /^[679][0-9]{8}$/,
        type: "tel",
        label: "Phone Number",
        inputMode: "numeric",
        startAdornment: <InputAdornment position="start">+34</InputAdornment>,
        component: "input",
        minLength: undefined,
        options: undefined,
        translationKeyPrefix: false,
      },
    ],
  },
];

const UserDetailsEdit = () => {
  return <Form form={formStep} />;
};

export default UserDetailsEdit;
