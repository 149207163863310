import React, { useMemo } from "react";
import { IPurchaseItem } from "../../../api/purchases";
import PurchaseCollapsableRow from "./PurchaseCollapsableRow";

interface IPurchasePorducts {
  items: IPurchaseItem[];
  open: boolean;
}

const PurchaseProducts = ({ items, open }: IPurchasePorducts) => {
  const tableHeadsData = () => {
    return [
      { value: "Product name" },
      { value: "Quantity", align: "right" as const },
      { value: "Price", align: "right" as const },
      { value: "Total amount", align: "right" as const },
    ];
  };

  const tableRowData = useMemo(() => {
    if (!items) return [];

    return items.map((item) => [
      { value: item.name, align: "left" as const, hash: item.order_hash },
      { value: item.quantity, align: "right" as const, hash: item.order_hash },
      { value: item.price, align: "right" as const, hash: item.order_hash },
      { value: item.total_amount, align: "right" as const, hash: item.order_hash },
    ]);
  }, [items]);

  return (
    <PurchaseCollapsableRow
      open={open}
      heading={"Products"}
      tableHeads={tableHeadsData()}
      tableRowData={tableRowData}
    ></PurchaseCollapsableRow>
  );
};

export default PurchaseProducts;
