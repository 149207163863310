/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types,react/jsx-props-no-spreading,react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import ReactMarkdown from "markdown-to-jsx";
import { Typography, Link, Box } from "@mui/material";

function MarkdownListItem(props: any) {
  return <Box component="li" sx={{ mt: 1, typography: "body1" }} {...props} />;
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h4",
        component: "h1",
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: "h6", component: "h2" },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: "subtitle1" },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "caption",
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem,
    },
  },
};

export default function Markdown(props: { children: string }) {
  const [readable, setReadable] = useState({ md: "" });

  useEffect(() => {
    void fetch(props.children)
      .then((res) => res.text())
      .then((md) => {
        setReadable({ md });
      });
  }, [props.children]);

  return (
    <ReactMarkdown options={options} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {readable.md}
    </ReactMarkdown>
  );
}
