import { createAsyncThunk } from "@reduxjs/toolkit";
import notifications, { Notification } from "../../api/notifications";
import fetchUserStatus from "./userThunk";

const fetchNotifications = createAsyncThunk<Notification[]>("notifications/fetchNotifications", async (_, thunkAPI) => {
  const { activeBusinessAccount } = thunkAPI.getState().business;

  if (!activeBusinessAccount) return [];

  const response = await notifications(activeBusinessAccount.hash);

  const { notifications: notificationsState } = thunkAPI.getState().notifications;

  if (response?.length === 0 && notificationsState.length > 0) {
    void thunkAPI.dispatch(fetchUserStatus());
  }

  return response || [];
});

export default fetchNotifications;
