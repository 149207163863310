import API from "./api";

const purchasesUrl = "/business/purchases/accounts/";

export type TPurchaseStatus = "completed" | "rejected" | "declined" | "abandoned" | "incomplete" | "in_progress";

export interface IPurchaseSearchParams {
  number?: string;
  status?: TPurchaseStatus;
  start_timestamp: string;
  end_timestamp: string;
}

export interface IPurchase {
  order_hash: string;
  created_timestamp: string;
  number: string;
  reference: string;
  first_name: string;
  status: TPurchaseStatus;
  total_amount: number;
  shop_status: string;
  shop_status_abbreviation: string;
  transaction?: IPurchaseTransaction | null;
  fees: IPurchaseFee[];
  items: IPurchaseItem[];
}

export interface IPurchaseTransaction {
  order_hash: string;
  amount: number;
  currency: string;
  created_timestamp: string;
  channel_value_date_timestamp: string;
  channel_hash: string;
  campaign_value_date_timestamp: string;
  campaign_hash: string;
}

export interface IPurchaseFee {
  order_hash: string;
  fee_type: string;
  amount: number;
  currency: string;
  created_timestamp: string;
  value_date_timestamp: string;
  channel_hash: string;
}

export interface IPurchaseItem {
  order_hash: string;
  name: string;
  quantity: number;
  price: number;
  total_amount: number;
}

export interface IPurchasesApiRequest {
  limit: number;
  offset: number;
  businessAccountHash: string;
  search?: IPurchaseSearchParams;
}

export interface IPurchasesApiItemsRequest {
  businessAccountHash: string;
  orderHash: string;
}

export const purchases = ({ limit, offset, businessAccountHash, search }: IPurchasesApiRequest) => {
  // TODO: add axios request cancel for older requests
  return API.get(`${purchasesUrl}${businessAccountHash}`, { params: { limit, from: offset, search } })
    .then(({ data }: { data: { data: IPurchase[] } }) => {
      if (data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};

export const purchasesTransactions = (purchasesHashes: string, businessAccountHash: string) => {
  // TODO: add axios request cancel for older requests
  return API.get(`${purchasesUrl}${businessAccountHash}/transactions`, { params: { order_hashes: purchasesHashes } })
    .then(({ data }: { data: { data: IPurchaseTransaction[] } }) => {
      if (data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};

export const purchasesFees = (purchasesHashes: string, businessAccountHash: string) => {
  // TODO: add axios request cancel for older requests
  return API.get(`${purchasesUrl}${businessAccountHash}/fees`, { params: { order_hashes: purchasesHashes } })
    .then(({ data }: { data: { data: IPurchaseFee[] } }) => {
      if (data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};

export const purchasesItems = ({ businessAccountHash, orderHash }: IPurchasesApiItemsRequest) => {
  // TODO: add axios request cancel for older requests
  return API.get(`${purchasesUrl}${businessAccountHash}/items`, { params: { order_hash: orderHash } })
    .then(({ data }: { data: { data: IPurchaseItem[] } }) => {
      if (data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
};
