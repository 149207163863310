import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import businessAccountsReducer from "./reducers/businessAccountsReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import purchasesReducer from "./reducers/purchasesReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    business: businessAccountsReducer,
    notifications: notificationsReducer,
    purchases: purchasesReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
