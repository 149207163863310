import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

interface IOfflineOrderQrCode {
  removeQrCode: () => void;
  qrCodeData: string;
  phoneNumber: string;
}

const OfflineOrderQrCode = ({ removeQrCode, qrCodeData, phoneNumber }: IOfflineOrderQrCode) => {
  const { t } = useTranslation("translation", { keyPrefix: "offlineOrder" });
  const history = useNavigate();

  return (
    <>
      <Box>
        {" "}
        <Typography component="h1" variant="h4">
          {t("Success!")}
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }} component="p">
          <Trans
            i18nKey="sentSmsToNumberToContinueProcess"
            values={{ phone: phoneNumber }}
            components={{ strong: <strong /> }}
          />
        </Typography>
        <Divider />
        <Typography sx={{ mt: 2, mb: 1 }} component="p">
          {t("Alternatively user can scan this QR code:")}
        </Typography>
      </Box>
      <Box>
        <QRCode value={qrCodeData} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button type="button" variant="outlined" color="secondary" size="small" onClick={removeQrCode}>
          {"<-"} {t("Back to edit form")}
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => history("/admin/purchases")}
          sx={{ marginLeft: 2 }}
          endIcon={<DoneIcon />}
        >
          {t("Close")}
        </Button>
      </Box>
    </>
  );
};

export default OfflineOrderQrCode;
