import React from "react";
import i18n from "i18next";
import CookiePolicyMarkdownES from "./es/CookiePolicy.md";
import CookiePolicyMarkdownEN from "./en/CookiePolicy.md";
import Markdown from "../../components/Markdown";
import { withTranslation } from "react-i18next";

const CookiePolicy = () => {
  const getCorrectDocument = () => {
    switch (i18n.language) {
      case "en-GB":
        return <Markdown>{CookiePolicyMarkdownEN}</Markdown>;
      case "es":
      default:
        return <Markdown>{CookiePolicyMarkdownES}</Markdown>;
    }
  };

  return getCorrectDocument();
};

export default withTranslation()(CookiePolicy);
