import React from "react";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import fiizyLogo from "../assets/img/fiizy_logo_no_text_transparent.svg";

const Copyright = () => {
  return (
    <Link color="inherit" to="/" component={NavLink} sx={{ width: { xs: 124, md: 194 } }}>
      <img src={fiizyLogo} alt="fiizy red logo" />
    </Link>
  );
};

export default Copyright;
