import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form, { IAccountActivationStep } from "../../../components/Form";
import { profilePasswordEdit } from "../../../api/user";
import { useAppSelector } from "../../../hooks/storeHooks";
import { RootState } from "../../../store/store";

const formStep: IAccountActivationStep[] = [
  {
    apiRequest: profilePasswordEdit,
    useDispatch: false,

    fields: [
      {
        name: "currentPassword",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "password",
        label: "Current Password",
        component: "input",
        inputMode: "password",
        minLength: 1,
        options: undefined,
        translationKeyPrefix: false,
      },
      {
        name: "newPassword",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "password",
        label: "New Password",
        component: "input",
        inputMode: "password",
        minLength: 6,
        options: undefined,
        translationKeyPrefix: false,
      },
    ],
  },
  {
    okMessage: "Password changed.",
  },
];

const selectNotifications = (state: RootState) => state.notifications.notifications;

const UserPasswordEdit = () => {
  const notifications = useAppSelector(selectNotifications);
  const history = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (notifications.length > 0) {
      history("/admin/dashboard");
    }
  }, [history, notifications.length]);

  return (
    <>
      <Typography component="h1" variant="h4">
        {t("Change password")}
      </Typography>
      <Form form={formStep} />
    </>
  );
};

export default UserPasswordEdit;
