import React, { ReactNode } from "react";
import usePermissionChecker from "../hooks/usePermissionChecker";

interface IGandalf {
  children: ReactNode;
  permission: string | undefined;
}

const Gandalf = ({ children, permission }: IGandalf) => {
  const hasPermission = usePermissionChecker(permission || "");
  return <>{hasPermission ? <>{children}</> : <></>}</>;
};

export default Gandalf;
