import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useInput from "../hooks/useInput";
import { loginEmailVerify, loginUserUsingEmail } from "../api/auth";
import CheckEmail from "../components/CheckEmail";

const LoginWithEmail = () => {
  const { value: email, bind: bindEmail } = useInput("");
  const [loading, setLoading] = useState(false);
  const [verifyEmailMessage, setVerifyEmailMessage] = useState(false);

  const { t } = useTranslation();

  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setLoading(true);
      void loginEmailVerify({ token }).then((data) => {
        if (data === null) navigate("/");
        else navigate("/admin/dashboard");
        setLoading(false);
      });
    }
    return () => {
      setLoading(false);
    };
  }, [navigate, token]);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);

    void loginUserUsingEmail({ email }).then(() => {
      setLoading(false);
      setVerifyEmailMessage(true);
    });
  };

  return (
    <>
      {verifyEmailMessage ? (
        <>
          <CheckEmail email={email} />
          <Typography
            color="text.secondary"
            variant="body1"
            sx={{ mt: 5, fontWeight: 600, cursor: "pointer" }}
            onClick={() => setVerifyEmailMessage(false)}
          >
            {t("Change email").toUpperCase()}
          </Typography>
        </>
      ) : (
        <>
          <Typography component="h1" variant="h4">
            {t("Sign in")}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="text.secondary">
            {t("Already have an account?")}{" "}
            <Link underline="none" component={NavLink} to="/">
              {t("Sign in")}
            </Link>
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email Address")}
              name="email"
              autoComplete="email"
              value={bindEmail.value}
              onChange={bindEmail.onChange}
              inputRef={emailInputRef}
              variant="standard"
              type="email"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5 }}
              disabled={loading || !email}
            >
              {t("Sign in")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default LoginWithEmail;
