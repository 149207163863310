import React from "react";
import { Box } from "@mui/material";
import bg from "../assets/img/bg.png";

import LanguageSwitcher from "../components/LanguageSwitcher";
import Copyright from "../components/Copyright";
import FooterNavigation from "../components/FooterNavigation";
import publicFooterRoutes from "../routes/public/publicFooterRoutes";
import { Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";

const PublicLayout = () => {
  return (
    <Box
      sx={{
        background: { xs: "none", md: `url(${bg})` },
        backgroundRepeat: { xs: "none", md: "no-repeat" },
        backgroundSize: { xs: "none", md: "50% 100%" },
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "1fr",
        height: "100%",
      }}
    >
      <Box
        sx={{
          padding: { xs: "40px 24px 0 24px", md: "56px 56px 0 56px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Copyright />
        <Box>
          <LanguageSwitcher />
        </Box>
      </Box>
      <Box
        sx={{
          pt: { xs: "32px", md: "36px" },
          ml: "10px",
          mr: "10px",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "40px",
            boxShadow: "0 0 44px 0 rgba(0, 0, 0, 0.02)",
            padding: { xs: "48px 16px", md: "48px 80px" },
            height: "100%",
            flex: 1,
            maxWidth: 1040,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Box>
          <Box />
        </Box>
      </Box>
      <FooterNavigation routes={publicFooterRoutes} />
    </Box>
  );
};
export default withTranslation()(PublicLayout);
