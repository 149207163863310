import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import privateRoutes from "../routes/private/privateRoutes";
import privateFooterRoutes from "../routes/private/privateFooterRoutes";
import Navigation from "../components/Navigation";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { isLoggedIn } from "../utils/authHelpers";
import fetchUserStatus from "../store/thunks/userThunk";
import { fetchActiveBusinessAccountInfo, fetchBusinessAccounts } from "../store/thunks/businessAccountsThunk";
import fetchNotifications from "../store/thunks/notificationsThunk";
import Loader from "../components/Loader";
import FooterNavigation from "../components/FooterNavigation";
import { Outlet, useNavigate } from "react-router-dom";
import usePermissionChecker from "../hooks/usePermissionChecker";
import { RootState } from "../store/store";

const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;
const selectNotifications = (state: RootState) => state.notifications.notifications;

const Admin = () => {
  const [loading, setLoading] = useState(true);
  const businessAccountInfoGetPermission = usePermissionChecker("get.business.settings.accounts.*");
  const activeBusinessAccountHash = useAppSelector(selectActiveBusinessAccount)?.hash || null;
  const notifications = useAppSelector(selectNotifications);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isLoggedIn()) {
      void dispatch(fetchUserStatus());
      void dispatch(fetchBusinessAccounts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessAccountInfoGetPermission) {
      void dispatch(fetchActiveBusinessAccountInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAccountInfoGetPermission]);

  useEffect(() => {
    void dispatch(fetchNotifications()).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBusinessAccountHash]);

  useEffect(() => {
    if (notifications.length > 0) {
      navigate("/admin/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {}, [notifications]);

  return (
    <Box sx={{ height: "100%" }}>
      <Navigation routes={privateRoutes} contentLoading={loading} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", pt: { xs: 2, md: 6 }, pl: { xs: 2, md: 6 }, width: "100%" }}
      >
        <Box
          sx={{
            bgcolor: "white",
            p: { xs: "48px 24px 48px 16px", md: 6 },
            mt: { xs: 3 },
            borderRadius: "40px 0 0 40px",
          }}
        >
          {loading ? <Loader color="#000000" size={50} /> : <Outlet />}
        </Box>
      </Box>
      <FooterNavigation routes={privateFooterRoutes} />
    </Box>
  );
};

export default Admin;
