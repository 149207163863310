import { useEffect } from "react";

// <link rel="stylesheet" type="text/css" href="https://cdn.fiizy.com/calculator/css/app.css">
const useStyles = (url: string) => {
  useEffect(() => {
    const stylesheetLink = document.createElement("link");

    stylesheetLink.rel = "stylesheet";
    stylesheetLink.type = "text/css";
    stylesheetLink.href = url;

    document.head.appendChild(stylesheetLink);

    return () => {
      document.head.removeChild(stylesheetLink);
    };
  }, [url]);
};

export default useStyles;
