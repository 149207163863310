import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IRouteModel from "../models/IRouteModel";
import fiizyLogo from "../assets/img/fiizy_logo_no_text_transparent.svg";
import { RootState } from "../store/store";
import { useAppSelector } from "../hooks/storeHooks";
import UserSettingsMenu from "./UserSettingsMenu";
import CloseIcon from "../assets/img/icons/close.svg";
import MenuIcon from "../assets/img/icons/menu.svg";
import Gandalf from "./Gandalf";

interface ISidebarProps {
  routes: IRouteModel[];
  contentLoading: boolean;
}

export const drawerWidth = "100%";

const selectNotifications = (state: RootState) => state.notifications.notifications;

const Navigation = ({ routes, contentLoading }: ISidebarProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const notifications = useAppSelector(selectNotifications);
  const container = document.body || null;
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  const menuItem = ({ layout, path, name, permission }: IRouteModel) => {
    const routePath = layout + path;
    return (
      <Gandalf permission={permission} key={routePath}>
        <Box
          component={NavLink}
          to={routePath}
          sx={{
            ml: { xs: 2, md: 5 },
            fontWeight: location.pathname === `${routePath}` ? "bold" : "normal",
            fontSize: 16,
            color: "#000",
            textDecoration: "none",
            mt: { xs: "12px", md: 0 },
            position: "relative",
          }}
        >
          {t(`routes.${name}`)}
          {location.pathname === `${routePath}` ? (
            <Box
              sx={{
                width: "100%",
                height: "3px",
                background: "black",
                position: "absolute",
                bottom: "-22px",
                display: { xs: "none", md: "block" },
              }}
            />
          ) : null}
        </Box>
      </Gandalf>
    );
  };

  const drawer = (
    <>
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          mt: { xs: "12px", md: 0 },
          mb: { xs: 3, md: 0 },
        }}
      >
        {!contentLoading && notifications.length === 0 && routes.map((route) => !route.hidden && menuItem(route))}
      </Box>
      <Divider sx={{ display: { xs: "block", md: "none" }, borderColor: "#dde4ea" }} />
      <Box
        sx={{ flexGrow: 0, m: { xs: "24px 16px", md: 0 }, display: "flex", flexWrap: { xs: "wrap", md: "no-wrap" } }}
      >
        <UserSettingsMenu contentLoading={contentLoading} />
      </Box>
    </>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          borderBottom: "none",
          backgroundColor: { xs: "#fff" },
        }}
        color="transparent"
        elevation={0}
      >
        <Toolbar
          sx={{
            p: { xs: "14px 16px", md: "0 40px" },
            justifyContent: { xs: "space-between", md: "initial" },
          }}
        >
          <Box component={NavLink} to="/" sx={{ height: { xs: 28, md: 32 } }}>
            <img src={fiizyLogo} alt="fiizy red logo" height="100%" width="auto" />
          </Box>
          <Box sx={{ display: { xs: "none", md: "contents" } }}>{drawer}</Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, p: "0" }}
          >
            <img src={MenuIcon} width={18} height={18} alt="menu icon" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", m: "14px 16px" }}>
            <NavLink to="/" style={{ height: 28 }}>
              <img src={fiizyLogo} alt="fiizy red logo" height="100%" width="auto" />
            </NavLink>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ p: "0" }}
            >
              <img src={CloseIcon} width={18} height={18} alt="close icon" />
            </IconButton>
          </Box>
          <Divider sx={{ borderColor: "#dde4ea" }} />
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Navigation;
