import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { logout } from "../utils/authHelpers";
import { RootState } from "../store/store";
import { useAppSelector } from "../hooks/storeHooks";
import AccountIcon from "../assets/img/icons/account.svg";
import LogoutIcon from "../assets/img/icons/logout.svg";
import SettingsIcon from "../assets/img/icons/settings.svg";
import BusinessAccountSelector from "./BusinessAccountSelector";

const selectUser = (state: RootState) => state.user.user;
const selectNotifications = (state: RootState) => state.notifications.notifications;

const UserSettingsMenu = ({ contentLoading }: { contentLoading: boolean }) => {
  const notifications = useAppSelector(selectNotifications);
  const user = useAppSelector(selectUser);
  const location = useLocation();

  return (
    <>
      <BusinessAccountSelector />
      {user && user.name && (
        <Box
          sx={{ fontSize: 16, display: "flex", alignItems: "center", flexGrow: 1, width: { xs: "60%", md: "initial" } }}
        >
          <img src={AccountIcon} width={18} height={18} alt="account icon" style={{ marginRight: 12 }} />
          {user.name}
        </Box>
      )}
      {!contentLoading && notifications.length === 0 ? (
        <Box
          sx={{
            ml: 2,
            bgcolor: { xs: "initial", md: location.pathname === "/admin/settings" ? "background.default" : null },
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: "0 16px", display: "flex", alignItems: "center" }} component={NavLink} to="/admin/settings">
            <img src={SettingsIcon} width={18} height={18} alt="settings icon" />
          </Box>
        </Box>
      ) : null}
      <Box sx={{ ml: 2, display: "flex", alignItems: "center" }} component={NavLink} to="/" onClick={logout}>
        <img src={LogoutIcon} width={18} height={18} alt="logout icon" />
      </Box>
    </>
  );
};

export default UserSettingsMenu;
