import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import periods, { IPeriod } from "../../utils/periods";

type TPeriodSelectorProps = {
  handlePeriodChange: (period: IPeriod) => void;
  value: IPeriod;
};

const PeriodSelector = ({ handlePeriodChange, value }: TPeriodSelectorProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "dates" });

  const handleChange = (newValue: { label: string; start: Date; end: Date }) => {
    const periodValue = periods.find(({ label }) => label === newValue.label) || value;
    handlePeriodChange(periodValue);
  };

  return (
    <>
      <List component="nav" aria-label="period selector">
        {periods.map((periodObject) => (
          <ListItemButton
            key={periodObject.label}
            selected={periodObject.label === value.label}
            onClick={() => handleChange(periodObject)}
          >
            <ListItemText primary={t(periodObject.label)} />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default PeriodSelector;
