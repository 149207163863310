import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import IRouteModel from "../models/IRouteModel";

interface IFooterNavigation {
  routes: IRouteModel[];
}

const FooterNavigation = ({ routes }: IFooterNavigation) => {
  const { t } = useTranslation();
  const location = useLocation();

  const menuItem = ({ path, name, layout }: IRouteModel) => {
    const routePath = layout + path;
    return (
      <Box
        key={routePath}
        component={NavLink}
        to={routePath}
        sx={{
          ml: { xs: 2, md: 5 },
          fontWeight: location.pathname === `${routePath}` ? "bold" : "normal",
          fontSize: 16,
          color: "#2b2e42",
          textDecoration: "none",
          mt: { xs: "12px", md: 0 },
        }}
      >
        {t(`routes.${name}`)}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        p: 3,
        textAlign: "center",
      }}
    >
      {routes.map((route) => menuItem(route))}
    </Box>
  );
};

export default FooterNavigation;
