const BUSINESS_ACCOUNT_STORAGE_KEY = "businessAccount";

export function setActiveBusinessAccountStorage(businessAccountHash: string): void {
  sessionStorage.setItem(BUSINESS_ACCOUNT_STORAGE_KEY, businessAccountHash);
}

export function deleteActiveBusinessAccountStorage(): void {
  sessionStorage.removeItem(BUSINESS_ACCOUNT_STORAGE_KEY);
}

export function getActiveBusinessAccountHashStorage(): string {
  return sessionStorage.getItem(BUSINESS_ACCOUNT_STORAGE_KEY) || "";
}
