/* eslint-disable react/jsx-props-no-spreading,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument */
import React, { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Controller } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
  required: string | boolean;
  pattern?: RegExp | undefined;
  minLength?: number | undefined;
  type: string;
  label: string;
  inputMode: "text" | "none" | "search" | "tel" | "url" | "email" | "numeric" | "decimal" | undefined;
  startAdornment?: ReactElement | undefined;
  endAdornment?: ReactElement | undefined;
  component?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  errors: FieldErrors;
  shrinkInputLabel?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform?: {
    input: (value: string | number) => string;
    output: (e: { target: { value: string } }) => number | string;
  };
  fieldArrayInputIndex?: number;
  fieldArrayKey?: string;
  fieldName?: string;
  min?: number;
  validate?: (val: string) => string;
  hideShowPassword?: boolean;
}

const FormTextInput = ({
  startAdornment,
  inputMode,
  errors,
  label,
  name,
  type,
  control,
  required,
  pattern,
  minLength,
  shrinkInputLabel,
  transform,
  fieldArrayInputIndex,
  fieldArrayKey,
  fieldName,
  min,
  validate,
  hideShowPassword,
}: IProps) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const errorObject = () => {
    if (fieldArrayKey && fieldArrayInputIndex !== undefined && fieldName) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return errors[fieldArrayKey]?.[fieldArrayInputIndex]?.[fieldName];
    }
    if (fieldArrayKey && fieldName) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return errors[fieldArrayKey]?.[fieldName];
    }

    return errors[name];
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        pattern,
        minLength,
        min,
        validate,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{ mt: 2.5 }}
          type={showPassword && type === "password" ? "text" : type}
          InputProps={{
            inputMode,
            startAdornment,
            endAdornment:
              type === "password" && !hideShowPassword ? (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          InputLabelProps={{
            shrink: shrinkInputLabel,
          }}
          onChange={(e) => field.onChange(transform ? transform.output(e) : e)}
          value={transform ? transform.input(field.value) : field.value}
          variant="standard"
          fullWidth
          label={t(label)}
          required={!!required}
          helperText={errorObject() && t(errorObject().message)}
          error={!!errorObject()}
        />
      )}
    />
  );
};

export default FormTextInput;
