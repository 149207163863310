import React from "react";

import Form, { IAccountActivationStep } from "../../../components/Form";
import { profilePasswordSetup } from "../../../api/user";

const formStep: IAccountActivationStep[] = [
  {
    apiRequest: profilePasswordSetup,
    useDispatch: false,

    fields: [
      {
        name: "password",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "password",
        label: "Password",
        component: "input",
        inputMode: "password",
        minLength: 6,
        options: undefined,
        translationKeyPrefix: false,
        hideShowPassword: true,
      },
      {
        name: "passwordRepeat",
        required: true,
        startAdornment: undefined,
        pattern: undefined,
        type: "password",
        label: "Confirm password",
        component: "input",
        inputMode: "password",
        minLength: 6,
        options: undefined,
        translationKeyPrefix: false,
        compare: "password",
        compareError: "Your passwords do not match",
        hideShowPassword: true,
      },
    ],
  },
];

const UserPasswordSet = () => {
  return <Form form={formStep} />;
};

export default UserPasswordSet;
