import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/index.scss";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import App from "./App";
import theme from "./theme";
import "./i18n";
import "./utils/logger";
import { store } from "./store/store";
import ScrollToTop from "./utils/ScrollToTop";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
