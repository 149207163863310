import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffc809",
    },
    secondary: {
      main: "#2b2e42",
    },
    success: {
      main: "#00c316",
    },
    error: {
      main: "#ff0226",
    },
    background: { default: "#f6f8f9" },
    action: {
      active: "#2b2e42",
      disabledBackground: "#fff0bb",
      disabled: "#777f89",
    },
    text: {
      primary: "#2b2e42",
      secondary: "#777f89",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h4: {
      fontSize: 34,
      fontWeight: 600,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 14,
    },
  },
  components: {
    // Name of the component
    MuiLink: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#6f90b8",
          fontWeight: "bold",
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 8,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#777f89",
          "&.Mui-focused": {
            color: "#777f89",
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
