import React from "react";
import i18n from "i18next";
import TermsAndConditionsMarkdownES from "./es/TermsAndConditions.md";
import TermsAndConditionsMarkdownEN from "./en/TermsAndConditions.md";
import Markdown from "../../components/Markdown";
import { withTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const getCorrectDocument = () => {
    switch (i18n.language) {
      case "en-GB":
        return <Markdown>{TermsAndConditionsMarkdownEN}</Markdown>;
      case "es":
      default:
        return <Markdown>{TermsAndConditionsMarkdownES}</Markdown>;
    }
  };
  return getCorrectDocument();
};
export default withTranslation()(TermsAndConditions);
