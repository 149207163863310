import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { RootState } from "../store/store";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { setActiveBusinessAccount } from "../store/reducers/businessAccountsReducer";

const selectBusinessAccounts = (state: RootState) => state.business.accounts;
const selectActiveBusinessAccount = (state: RootState) => state.business.activeBusinessAccount;

const BusinessAccountSelector = () => {
  const businessAccountsList = useAppSelector(selectBusinessAccounts);
  const activeBusinessAccount = useAppSelector(selectActiveBusinessAccount);

  const dispatch = useAppDispatch();

  return (
    <>
      {businessAccountsList.length > 1 ? (
        <FormControl sx={{ mr: 4, minWidth: 120, justifyContent: "center" }} size="small" variant="standard">
          <Select
            labelId="businessAccountName"
            id="businessAccountName"
            value={activeBusinessAccount ? activeBusinessAccount.hash : ""}
            onChange={(event: SelectChangeEvent) => dispatch(setActiveBusinessAccount(event.target.value))}
            label="Business Name"
            style={{ fontSize: "16px" }}
          >
            {businessAccountsList.map(({ hash, name, business_name }) => (
              <MenuItem key={hash} value={hash} dense style={{ fontSize: "16px" }}>
                {name || business_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box sx={{ fontSize: 16, display: "flex", alignItems: "center", flexGrow: 1, mr: 4 }}>
          {activeBusinessAccount ? activeBusinessAccount.business_name : null}
        </Box>
      )}
    </>
  );
};

export default BusinessAccountSelector;
