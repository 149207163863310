import React from "react";

interface ILoaderProps {
  color?: string;
  size?: number;
}

const Loader = ({ color = "#000000", size = 50 }: ILoaderProps) => (
  <div className="loader" style={{ color, fontSize: `${size}px` }} />
);

export default Loader;
