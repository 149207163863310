/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";

interface ISelectOption {
  value: string;
  label: string;
}

interface IProps {
  label: string;
  name: string;
  value: string;
  options: ISelectOption[];
  control: Control;
  required: string | boolean;
  pattern: RegExp | undefined;
  minLength: number | undefined;
  translationKeyPrefix: boolean | string;
  errors: FieldErrors;
}

const FormSelect = ({
  translationKeyPrefix,
  label,
  name,
  options,
  control,
  required,
  pattern,
  minLength,
  errors,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required, pattern, minLength }}
      render={({ field }: { field: { value: string } }) => (
        <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors[name]}>
          <InputLabel id={name}>{t(label)}</InputLabel>
          <Select labelId={name} id={name} {...field} value={field.value || ""} label={label}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {translationKeyPrefix ? t(`${translationKeyPrefix as string}.${option.label}`) : option.label}
              </MenuItem>
            ))}
          </Select>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {errors && <FormHelperText>{t(errors[name]?.message)}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
