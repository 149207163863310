import IRouteModel from "../../models/IRouteModel";
import PluginsPrivate from "../../views/Plugins/PluginsPrivate";
import MediaKitPrivate from "../../views/MediaKit/MediaKitPrivate";

const publicFooterLinks: IRouteModel[] = [
  {
    path: "/plugins",
    name: "Plugins",
    component: PluginsPrivate,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/media-kit",
    name: "Media kit",
    component: MediaKitPrivate,
    layout: "/admin",
    hidden: true,
  },
];

export default publicFooterLinks;
