/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { endOfDay, isBefore, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { IPeriod } from "../../utils/periods";
import DatePicker from "./DatePicker";
import PeriodSelector from "./PeriodSelector";
import { formatDateForDisplay } from "../../utils/formatDate";

const DatepickerWithPeriods = ({
  dateChangeHandler,
  defaultPeriod,
}: {
  dateChangeHandler: (period: IPeriod) => void;
  defaultPeriod: IPeriod;
}) => {
  const [period, setPeriod] = React.useState<IPeriod>(defaultPeriod);
  const [customPeriod, setCustomPeriod] = React.useState<{
    label: string;
    start: Date | null;
    end: Date | null;
  }>({ label: "Custom", start: null, end: null });
  const [periodSelectorOpen, setPeriodSelectorOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("translation", { keyPrefix: "purchases" });

  useEffect(() => {
    if (!periodSelectorOpen && customPeriod.start && customPeriod.end === null) {
      const newCustomPeriodObject = {
        label: "Custom period",
        start: startOfDay(customPeriod.start),
        end: endOfDay(customPeriod.start),
      };
      setPeriod(newCustomPeriodObject);
      dateChangeHandler(newCustomPeriodObject);
    } else if (!periodSelectorOpen) {
      dateChangeHandler(period);
      setCustomPeriod(period);
    }
  }, [periodSelectorOpen]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPeriodSelectorOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = periodSelectorOpen && Boolean(anchorEl);
  const id = canBeOpen ? "period-popper" : undefined;

  const handlePeriodChange = (value: IPeriod) => {
    setPeriod(value);
    setCustomPeriod(value);
    setPeriodSelectorOpen(false);
  };

  const pickerDateChange = (newDate: Date) => {
    let { start, end } = customPeriod;

    if (start !== null && end !== null) {
      start = startOfDay(newDate);
      end = null;
      setCustomPeriod({ label: "Custom period", start, end });
      return;
    }
    if (start === null) {
      start = startOfDay(newDate);
      end = null;
      setCustomPeriod({ label: "Custom period", start, end });
      return;
    }

    if (end === null) {
      if (isBefore(newDate, start)) {
        end = endOfDay(start);
        start = startOfDay(newDate);
        setCustomPeriod({ label: "Custom period", start, end });
        setPeriod({ label: "Custom period", start, end });
        return;
      }
      end = endOfDay(newDate);
      setCustomPeriod({ label: "Custom period", start, end });
      setPeriod({ label: "Custom period", start, end });
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setPeriodSelectorOpen(false)}>
      <Box>
        <TextField
          aria-describedby={id}
          label={t("Period")}
          value={`${formatDateForDisplay(customPeriod.start || period.start)} - ${formatDateForDisplay(
            customPeriod.end || period.end
          )}`}
          variant="standard"
          onClick={handleClick}
          sx={{ width: "240px" }}
        />
        <Popper style={{ zIndex: 1200 }} id={id} open={periodSelectorOpen} anchorEl={anchorEl} placement="bottom-start">
          <Box
            sx={{
              border: 1,
              borderColor: "secondary.main",
              borderRadius: 10,
              p: 2,
              bgcolor: "background.paper",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr auto" },
            }}
          >
            <PeriodSelector handlePeriodChange={handlePeriodChange} value={period} />
            <Box sx={{ textAlign: "center" }}>
              <DatePicker start={customPeriod.start} end={customPeriod.end} pickerDateChange={pickerDateChange} />
              <Button
                sx={{ width: 160 }}
                variant="outlined"
                color="secondary"
                type="button"
                onClick={() => setPeriodSelectorOpen(false)}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default DatepickerWithPeriods;
